import React from "react";
import { CommentOutlined, DownloadCircleOutlined, HeartFilled, HeartOutlined } from "../../OldDesign/Icons";
import { useStore } from "../../../store/useStore";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { User } from "types/user";

interface ActionBarProps {
  handleScrollToCommentSection: () => void;
  user: User;
  setMetaCv: (user: User) => void;
}

const ActionBar = ({ handleScrollToCommentSection, user, setMetaCv }: ActionBarProps) => {
  const handleFavMetaCv = useStore((state) => state.handleFavMetaCv);
  const downloadMetaCv = useStore((state) => state.downloadMetaCv);

  const handleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleFavMetaCv(user.id, user.company.is_favourite[0].id);
  };
  const handleMetaCvDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    downloadMetaCv(user.id, user.basic.name[0].id);
  };
  return (
    <div className="flex flex-row items-start justify-start gap-3 p-1.5 max-lg:flex-row max-lg:px-0 lg:rounded-t-lg">
      <Button
        type="button"
        onClick={() => {
          handleScrollToCommentSection();
        }}
        className="rounded-lg border-none bg-primary-200 p-2.5 max-lg:flex max-lg:flex-1 max-lg:items-center max-lg:justify-center max-lg:bg-neutral-100"
      >
        <span className="text-[14px] font-medium leading-tight text-primary-600 lg:hidden">Write a review</span>

        <CommentOutlined className="h-5 w-5 text-primary-500 max-lg:hidden" />
      </Button>
      <Button
        onClick={handleMetaCvDownload}
        className="rounded-lg border-none bg-primary-75 p-2.5 max-lg:flex max-lg:flex-1 max-lg:items-center max-lg:justify-center max-lg:bg-neutral-100"
      >
        <DownloadCircleOutlined className="h-5 w-5 text-primary-500" />
      </Button>
      <Button
        onClick={handleFavorite}
        className="rounded-lg border-none bg-secondary_recruiter-200 p-2.5 text-secondary_recruiter-600"
      >
        <HeartFilled
          className={`absolute  h-5 w-5 transition-all ${user.company.is_favourite[0].id ? "scale-100" : "scale-0"} `}
        />
        <HeartOutlined
          className={`h-5 w-5 transition-all  ${user.company.is_favourite[0].id ? "scale-0" : "scale-100"} `}
        />
      </Button>
    </div>
  );
};

export default ActionBar;
