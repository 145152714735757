import { useEffect, useRef } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "utils/useIsMobile";
import { CloseCircleIcon } from "components/OldDesign/Icons";
import { Guideline } from "data/Guideline";
import Sidebar from "components/NewDesign/Sidebar/Sidebar";

const GuideModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div className="relative flex h-[700px] w-[1238px] p-9 pb-3 max-lg:h-auto max-lg:w-screen max-lg:flex-col max-lg:p-0">
      <button onClick={() => navigate("/dashboard")} className="absolute right-2 top-2">
        <CloseCircleIcon className={"h-6 w-6"} />
      </button>
      {/* SIDEBAR */}
      <Sidebar title={"Kılavuz"} />
      {/* OUTLET */}
      <div ref={contentRef} className="h-full w-full max-w-full flex-1 overflow-y-auto px-6 max-lg:px-4">
        <Outlet />
      </div>
    </div>
  );
};

export default GuideModal;
