import React, { useEffect, useState } from "react";
import { ConnectionsWorld } from "../../../components/NewDesign/Illustrations";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import SearchBar from "../../../components/NewDesign/SearchBar/SearchBar";
import { useStore } from "../../../store/useStore";
import Loading from "../../../components/OldDesign/Loading";
import { getNetworkInvitees } from "../../../api/endpoints";
import GroupFilters from "../../../components/NewDesign/GroupFilters";
import { EyeFilled, RubbishOutlined, WaitingOutlined } from "../../../components/OldDesign/Icons";
import ConfirmationPopup from "../../../components/NewDesign/Popups/ConfirmationPopup";
import IsMobile from "../../../utils/IsMobile";
import { useLocation, useNavigate } from "react-router-dom";
import MobileMetaCv from "../../../components/MobileMetaCv/MobileMetaCv";
import Pagination from "../../../components/OldDesign/Pagination";
import Button from "../../../components/NewDesign/Button/Button";
import SortBar from "components/NewDesign/SortBar";
import { INVITEES_SORT_OPTIONS } from "components/NewDesign/constants";

const INVITEES_GROUPS = ["invited", "account_linked", "playing", "filling_core_cv", "completed"];
const INVITEES_SOURCES = ["candidate", "employee"];

const Invitees = () => {
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const deleteInvite = useStore((state) => state.deleteInvite);
  const resendInvite = useStore((state) => state.resendInvite);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [inviteeList, setInviteeList] = useState({
    allUserCounts: {},
    paginationInfo: {},
    memberList: [],
    isLoaded: false,
    isLoading: false,
  });

  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);

  const [sortOption, setSortOption] = useState({
    sortby: "invite_date",
    ascending: 0,
  });

  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteInviteeId, setDeleteInviteeId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);

  const [resendMsg, setResendMsg] = useState("");
  const [resentInviteeId, setResentInviteeId] = useState(null);
  const [showResendModal, setShowResendModal] = useState(false);

  // PAGINATION
  const [pageSize, setPageSize] = useState(24); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page

  const handleRemoveInvite = (user) => {
    // const deleteInviteeName = inviteeList.memberList.find((invite) => invite.id === id).inviteUserName;
    setDeleteMsg(`"${user.basic.user[0].name}" davetini silmek istediğinize emin misiniz?`);
    setDeleteInviteeId(user.id);
    setShowDeleteModal(true);
  };

  const handleResendInvite = (user) => {
    // const resendInviteeName = inviteeList.memberList.find((invite) => invite.id === id).inviteUserName;
    setResendMsg(`"${user.basic.user[0].name}" davetini tekrar göndermek istediğinize emin misiniz?`);
    setResentInviteeId(user.id);
    setShowResendModal(true);
  };

  useEffect(() => {
    const options = {
      page: currentPage,
      rows: pageSize,
      keyword: searchKeyword,
      filter: {
        user_source: selectedSources,
        user_groups: selectedUserGroups,
      },
      sort: sortOption,
    };

    getNetworkInvitees(
      options,
      null,
      (res) => {
        setInviteeList((prev) => {
          return {
            ...prev,
            allUserCounts: res.data.search_metadata.filter_counts,
            paginationInfo: res.data.search_metadata.pagination_info,
            memberList: res.data.rows,
            isLoaded: true,
          };
        });
      },
      (err) => {
        setInviteeList((prev) => {
          return { ...prev, isLoaded: false };
        });
      },
      (bool) =>
        setInviteeList((prev) => {
          return { ...prev, isLoading: bool };
        }),
    );
  }, [sortOption, selectedUserGroups, searchKeyword, deleteCount, selectedSources, currentPage, pageSize]);

  return (
    <>
      {/* <p className="pb-2 text-[14px]">{content["invitees aciklama"]}</p> */}
      <div className="flex items-center gap-2 py-4 max-lg:py-2">
        <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        {/* <FilterBar /> */}
        <SortBar sortOptions={INVITEES_SORT_OPTIONS} sortOption={sortOption} setSortOption={setSortOption} />

        {/* Filters */}
      </div>

      <GroupFilters
        setCurrentPage={setCurrentPage}
        fetching={inviteeList.isLoading}
        selectedUserGroups={selectedUserGroups}
        setSelectedUserGroups={setSelectedUserGroups}
        userGroupFilters={INVITEES_GROUPS}
        sourceFilters={INVITEES_SOURCES}
        allUserCounts={inviteeList.allUserCounts}
        setSelectedSources={setSelectedSources}
        selectedSources={selectedSources}
      />

      <div className="flex flex-col gap-1.5">
        {/* columns */}
        <div className="flex items-center justify-between rounded-xl bg-neutral-200 px-4 py-2 max-lg:hidden">
          <div className="basis-[30%]">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Ad Soyad/E-posta</p>
          </div>
          <div className="basis-[10%]">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Tip</p>
          </div>
          <div className="basis-[20%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Meta CV Level</p>
          </div>
          {/* <div className="basis-[15%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Şirket İsmi</p>
          </div> */}
          <div className="basis-[10%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Tarih</p>
          </div>
          <div className="basis-[20%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Durum</p>
          </div>
          <div className="basis-[10%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyonlar</p>
          </div>
        </div>
        {/* list */}
        {inviteeList.isLoading ? (
          <Loading />
        ) : !inviteeList.memberList.length ? (
          <div className="py-12">
            <ConnectionsWorld className="mx-auto w-1/2 pb-9" />
            <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
              {content["bos tablo aciklama"]}
            </p>
          </div>
        ) : (
          <>
            {/* List */}
            {inviteeList.memberList.map((item, index) => (
              <UserRow
                handleRemoveInvite={handleRemoveInvite}
                handleResendInvite={handleResendInvite}
                key={index}
                user={item}
              />
            ))}
            <Pagination
              dataLength={inviteeList.paginationInfo.total_row_count}
              setPageSize={setPageSize}
              currentPage={currentPage}
              totalCount={inviteeList.paginationInfo.total_row_count}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
      <ConfirmationPopup
        onSuccess={() => setDeleteCount((prev) => prev + 1)}
        message={deleteMsg}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        id={deleteInviteeId}
        confirmModal={deleteInvite}
        confirmationTopic={"Delete"}
      />
      <ConfirmationPopup
        onSuccess={() => setDeleteCount((prev) => prev + 1)}
        message={resendMsg}
        showModal={showResendModal}
        setShowModal={setShowResendModal}
        id={resentInviteeId}
        confirmModal={resendInvite}
        confirmationTopic={"Resend"}
      />
    </>
  );
};

const UserRow = ({ user, handleRemoveInvite, handleResendInvite }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOverlayOpen = (id) => {
    setSelectedItemId(id);
    setShowOverlay(true);
  };
  const handleModalOpen = (e) => {
    IsMobile()
      ? handleOverlayOpen(user.basic.user[0].id)
      : navigate(`/view/${user.basic.user[0].id}`, {
          state: { previousLocation: location },
        });
  };
  return (
    <>
      <div className="relative flex items-center justify-between rounded-xl border border-secondary_recruiter-300 px-4 py-2 max-lg:flex-row max-lg:flex-wrap max-lg:items-start max-lg:gap-1 max-lg:p-3">
        <div className="flex flex-col gap-0.5 overflow-hidden px-0.5 max-lg:order-1 max-lg:basis-[80%] lg:basis-[30%]">
          <p className="text-sm font-medium leading-tight text-primary-900 max-lg:text-[15px]">
            {user.basic.user[0].name}
          </p>
          <p className="text-xs font-medium leading-tight text-primary-700 max-lg:text-[13px] max-lg:opacity-50">
            {user.communications?.email[0].name || "N/A"}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 text-center max-lg:order-3 lg:basis-[10%] lg:text-left">
          <p
            className={`${
              !user.company.is_employee[0].id ? "text-secondary_applicant-600" : "text-secondary_recruiter-700"
            } text-sm font-medium leading-tight max-lg:text-[13px] max-lg:opacity-50`}
          >
            {user.company.is_employee[0].name}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 max-lg:order-4 max-lg:basis-full lg:basis-[20%]">
          <div className="mb-1 h-[20px] w-full shrink-0 overflow-hidden rounded-full bg-primary-100">
            <div
              style={{
                width: user.basic.level[0].id > 0 ? (100 * user.basic.level[0].id) / 3 + "%" : "100%",
              }}
              className={`${
                user.basic.level[0].id > 0
                  ? "bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] text-neutral-100"
                  : "bg-transparent text-neutral-900"
              } flex h-full items-center justify-center gap-1 rounded-full px-2 py-0.5 text-center leading-none`}
            >
              <p className={`whitespace-nowrap text-[13px]`}>Level {user.basic.level[0].id}</p>
            </div>
          </div>
          {user.basic.level[0].id > 0 && (
            <p className="text-[14px] font-medium leading-tight text-secondary_recruiter-600">{user.last_played}</p>
          )}
        </div>
        {/* <div className="overflow-hidden px-0.5 text-center max-lg:order-5 lg:basis-[15%]">
          <p className="text-sm font-medium leading-tight text-primary-700 max-lg:text-[13px] max-lg:opacity-50">
            {user.company.company_name[0].name || "N/A"}
          </p>
        </div> */}
        <div className="overflow-hidden px-0.5 text-center max-lg:order-6 lg:basis-[10%]">
          <p className="text-sm font-medium leading-tight text-primary-700 max-lg:text-[13px] max-lg:opacity-50">
            {user.company.invite_date[0].name || "N/A"}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 max-lg:order-7 max-lg:basis-full lg:basis-[20%] lg:px-3">
          {user.basic.account_progress[0].id === "completed" ? (
            <button className="w-full" onClick={(e) => handleModalOpen(e)}>
              <TagWithIcon
                icon={<EyeFilled className={"h-4 w-4"} />}
                text={user.basic.account_progress[0].name}
                className={"bg-success-100 text-success-400"}
              />
            </button>
          ) : (
            <TagWithIcon
              text={user.basic.account_progress[0].name}
              className={`${
                ["account_linked", "invited"].includes(user.basic.account_progress[0].id)
                  ? "bg-warning-100 text-warning-400"
                  : [
                      "completed_istanbul",
                      "playing_istanbul",
                      "completed_barcelona",
                      "playing_barcelona",
                      "completed_amsterdam",
                      "playing_amsterdam",
                    ].includes(user.basic.account_progress[0].id)
                  ? "bg-primary-75 text-primary-600"
                  : user.basic.account_progress[0].id === "filling_core_cv"
                  ? "bg-secondary_recruiter-100 text-secondary_recruiter-600"
                  : user.basic.account_progress[0].id === "completed"
                  ? "bg-success-100 text-success-400"
                  : "bg-secondary_recruiter-100 text-secondary_recruiter-700"
              }`}
            />
          )}
        </div>
        <div className="flex basis-[5%] items-center justify-center gap-1 px-0.5 max-lg:order-2 max-lg:basis-[10%] lg:px-3">
          <Button
            type="button"
            disabled={user.basic.actions[0].state === "rejected"}
            className="rounded-lg border-none bg-success-100 p-2.5"
            onClick={() => handleResendInvite(user)}
            icon={<WaitingOutlined className="h-[22px] w-[22px] text-success-400 max-lg:h-4 max-lg:w-4" />}
          ></Button>
          <Button
            type="button"
            disabled={user.basic.actions[1].state === "rejected"}
            className="rounded-lg border-none bg-card_candidate-100 p-2.5"
            onClick={() => handleRemoveInvite(user)}
            icon={<RubbishOutlined className="h-[22px] w-[22px] text-card_candidate-400 max-lg:h-4 max-lg:w-4" />}
          ></Button>
        </div>
      </div>
      {showOverlay && <MobileMetaCv id={selectedItemId} onClose={() => setShowOverlay(false)} />}
    </>
  );
};

export default Invitees;
