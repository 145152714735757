import React, { useState } from "react";
import { Form } from "./Forms/Form";
import { Field } from "./Forms/Field";
import { Input } from "./Forms/Input";
import { CloseCircleIcon } from "../OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import Button from "./Button/Button";
import { useForm } from "react-hook-form";
import { sendSupportTicket } from "../../api/endpoints";
import { toast } from "react-toastify";
import InviteFormSuccessPopup from "./Popups/InviteFormSuccessPopup";
import { useStore } from "../../store/useStore";

const StartADiscussionForm = () => {
  const appLanguage = useStore((state) => state.appLanguage);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const sendQuestion = async (formData) => {
    // console.log(formData);
    const payload = {
      title: formData.support_title,
      description: formData.support_topic + ":" + formData.support_message,
    };
    await sendSupportTicket(
      payload,
      () => {
        // navigate(-1);
        // toast.success("Ticket is sent");
        setSuccess(true);
      },
      (err) => {
        toast.error("Ticket cannot sent");
      },
    );
  };
  const discussuionFormContent = {
    tr: {
      title: "Müzakere Başlat",
      description: "Sonuçlarda bir hata olduğunu düşünüyorsanız bunu bilmemizi sağlayın.",
      button: "Gönder",
      messageHeaderLabel: "Başlık",
      messageTopicLabel: "Konu",
      messageLabel: "Mesajınız",
    },
    en: {
      title: "Start a Discussion",
      description: "If you think there is an error in the results, let us know.",
      button: "Send",
      messageHeaderLabel: "Title",
      messageTopicLabel: "Topic",
      messageLabel: "Your Message",
    },
  };
  return (
    <>
      {success ? (
        <InviteFormSuccessPopup />
      ) : (
        <div className="max-w-[450px] rounded-xl bg-neutral-200 px-9 py-8 max-lg:max-w-[370px] max-lg:p-4">
          <div className="pb-4">
            <div className="flex flex-row justify-between">
              <h2 className="mb-2 border-l-2 border-primary-900 pl-2 text-[22px] font-semibold leading-tight text-primary-900">
                {discussuionFormContent[appLanguage].title}
              </h2>
              <button type="button" onClick={() => navigate(-1)}>
                <CloseCircleIcon className="h-6 w-6" />
              </button>
            </div>
            <p className="text-[17px] leading-tight text-primary-900">
              {discussuionFormContent[appLanguage].description}
            </p>
          </div>
          <Form className="flex flex-col gap-3" onSubmit={handleSubmit(sendQuestion)}>
            <Field label={discussuionFormContent[appLanguage].messageHeaderLabel}>
              <Input {...register("support_title")} id="support_title" />
            </Field>
            <Field label={discussuionFormContent[appLanguage].messageTopicLabel}>
              <Input {...register("support_topic")} id="support_topic" />
            </Field>
            <Field label={discussuionFormContent[appLanguage].messageLabel}>
              <textarea {...register("support_message")} id="support-message" className="p-4" cols={50} rows={4} />
            </Field>
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting} className="self-end">
              {discussuionFormContent[appLanguage].button}
            </Button>
          </Form>
        </div>
      )}
    </>
  );
};

export default StartADiscussionForm;
