import { useStore } from "store/useStore";
import CommunityRecruiter from "../../recruiter/Community/CommunityRecruiter";
import CommunityCandidate from "./CommunityCandidate";

const Community = () => {
  const user = useStore((state) => state.user.data);
  return <>{user.userType === "candidate" ? <CommunityCandidate /> : <CommunityRecruiter />}</>;
};

export default Community;
