import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import App from "./App.tsx";
import "./index.css";
import { history } from "./utils/history";
import CustomBrowserRouter from "./utils/CustomBrowserRouter";
import { TopScroller } from "./utils/TopScroller";

import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";

Sentry.init({
  dsn: "https://a29aca900ee7db461b85a52558cbee76@o4508267438211072.ingest.de.sentry.io/4508290246508624",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.humanas\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // environment: process.env.REACT_APP_ENVIRONMENT, // Sets environment based on your build setup
  beforeSend(event, hint) {
    // Only capture errors in production
    if (process.env.REACT_APP_ENVIRONMENT !== "production") {
      return null; // Ignore events in non-production and non-test environments
    }

    return event;
  },
});

const tagManagerArgs = {
  gtmId: "GTM-5HJJHJM3",
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CustomBrowserRouter history={history}>
    {/* <React.StrictMode> */}
    <App />
    <TopScroller />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    {/* </React.StrictMode> */}
  </CustomBrowserRouter>,
);
