import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useStore } from "./store/useStore";

import ProtectedRoute from "./utils/ProtectedRoute";
import Login from "./pages/auth/Login";
import CandidateSignUp from "./pages/auth/CandidateSignUp";
import RecruiterSignUp from "./pages/auth/RecruiterSignUp";
import PasswordSet from "./pages/auth/PasswordSet";
import PasswordReset from "./pages/auth/PasswordReset";
import CodeActivation from "./pages/auth/CodeActivation";
import PasswordLogin from "./pages/auth/PasswordLogin";
import AuthLayout from "./layout/AuthLayout";
import MetaCvDialogue from "./pages/auth/MetaCvDialogue";
import LazyGuruDialogue from "./pages/auth/LazyGuruDialogue";

import DashboardLayout from "./layout/DashboardLayout";
import Dashboard from "./pages/recruiter/Dashboard/Dashboard";

import ProtectedAuthRoute from "./utils/ProtectedAuthRoute";
import Overview from "./pages/candidate/Overview";
import Logout from "./components/NewDesign/Logout";
import Community from "./pages/candidate/Community/Community";
import ApplicationForm from "./pages/candidate/Community/ApplicationForm";
import MobileApplicationForm from "./pages/candidate/Community/MobileApplicationForm";

import UserProfileForm from "./components/NewDesign/MultiStepForm/UserProfileForm";
import JobDescriptionForm from "./components/NewDesign/MultiStepForm/JobDescriptionForm";
import EducationalStatusForm from "./components/NewDesign/MultiStepForm/EducationalStatusForm";
import LanguageLevelForm from "./components/NewDesign/MultiStepForm/LanguageLevelForm";
import IsMobile from "./utils/IsMobile";
import PhoneConfirmationForm from "./components/NewDesign/MultiStepForm/PhoneConfirmationForm";
import MyNetwork from "./pages/recruiter/MyNetwork/MyNetwork";
import Connections from "./pages/recruiter/MyNetwork/Connections";
import Invitees from "./pages/recruiter/MyNetwork/Invitees";
import Pool from "./pages/recruiter/MyNetwork/Pool";
import ChooseUserType from "./pages/auth/ChooseUserType";

import Modal from "./components/OldDesign/Modal";
import SupportForm from "./components/NewDesign/SupportForm";
import InviteForm from "./components/NewDesign/InviteForm";
import MetaCvView from "./components/NewDesign/PrintableCv/MetaCvView";
import JoinCommunityPopup from "./components/NewDesign/JoinCommunityPopup";
import StartADiscussionForm from "./components/NewDesign/StartADiscussionForm";
import InviteFormSuccessPopup from "./components/NewDesign/Popups/InviteFormSuccessPopup";
import MyGroups from "./pages/recruiter/MyNetwork/MyGroups";
import CreateGroupForm from "./components/NewDesign/CreateGroupForm";
import MatchGroups from "./components/NewDesign/MatchGroups";
import CandidateProfileSettings from "./pages/candidate/Account/CandidateProfileSettings";
import SharePage from "./pages/recruiter/SharePage";
import CompanyAutoLogin from "./components/NewDesign/CompanyAutoLogin";
import GroupReport from "./components/NewDesign/GroupReport";
import Reports from "./pages/recruiter/MyNetwork/Reports";
import Settings from "./pages/Settings";
import CandidateAccountSettings from "./pages/candidate/Account/CandidateAccountSettings";
import RecruiterProfileSettings from "./pages/recruiter/Account/RecruiterProfileSettings";
import HumanasAid from "./pages/recruiter/AID/HumanasAid";
import AidPage from "./pages/recruiter/AID/AidPage";
import AidOverviewSubPage from "./pages/recruiter/AID/AidOverviewSubPage";
import AidSectorsSubPage from "./pages/recruiter/AID/AidSectorsSubPage";
import AidInterviewSubPage from "./pages/recruiter/AID/AidInterviewSubPage";
import AidCharacteristicSubPage from "./pages/recruiter/AID/AidCharacteristicSubPage";
import Pricing from "./pages/Pricing";
import DownloadMetaCv from "./pages/DownloadMetaCv";
import AidSummaryPage from "./pages/recruiter/AID/AidSummaryPage";
import AidIDsPage from "./pages/recruiter/AID/AidIDsPage";
import AidCNAPage from "./pages/recruiter/AID/AidCNAPage";
import AidServicesPage from "./pages/recruiter/AID/AidServicesPage";
import CompanyProfileSettings from "./pages/recruiter/Account/CompanyProfileSettings";
import ExperienceForm from "./components/NewDesign/MultiStepForm/ExperienceForm";
// import CNAFirstAnalysis from "./pages/recruiter/CNA/CNAFirstAnalysis";
import CNAMainPage from "./pages/recruiter/CNA/CNAMainPage";
import JobPositionAnalysis from "./pages/recruiter/CNA/JobPositionAnalysis";
import AnalysisHomePage from "./pages/recruiter/CNA/JobPositionAnalysis/AnalysisHomePage";
import OrientationTournament from "./pages/recruiter/CNA/JobPositionAnalysis/OrientationTournament";
import CorecvForm from "./pages/recruiter/CNA/JobPositionAnalysis/CorecvForm";
import MyAnalyses from "./pages/recruiter/CNA/MyAnalyses/MyAnalyses";
import AnalysisReport from "./pages/recruiter/CNA/MyAnalyses/AnalysisReport";

import MyJobPositions from "./pages/recruiter/CNA/JobPositionAnalysis/MyJobPositions";
import AddJobPosition from "./pages/recruiter/CNA/JobPositionAnalysis/AddJobPosition";
import JobPositionResult from "./pages/recruiter/CNA/MyAnalyses/JobPositionResult";
import CNA from "./pages/recruiter/CNA/CNA";
import HumanasIds from "./pages/recruiter/CNA/HumanasIds";
import JobPosReports from "./pages/recruiter/CNA/JobPosReports";
import PlayLazyGuru from "./components/NewDesign/PlayLazyGuru";
import GuideModal from "./pages/recruiter/Dashboard/GuideModal";
import "./App.css";
import AIDGuide from "pages/recruiter/Dashboard/Guides/AIDGuide";
import CNAGuide from "pages/recruiter/Dashboard/Guides/CNAGuide";
import UserReports from "pages/recruiter/CNA/UserReports";
import HowMetaCVCreated from "pages/recruiter/Dashboard/Guides/HowMetaCVCreated";
import WhatIsMetaCV from "pages/recruiter/Dashboard/Guides/WhatIsMetaCV";
import MyConnections from "pages/recruiter/Dashboard/Guides/MyConnections";
import Match from "pages/recruiter/Dashboard/Guides/Match";
import MyGroupsGuide from "pages/recruiter/Dashboard/Guides/MyGroups";
import IdealProfile from "pages/recruiter/Dashboard/Guides/IdealProfile";
import IndividualCompatibilityReport from "pages/recruiter/Dashboard/Guides/IndividualCompatibilityReport";
import MyPositionReport from "pages/recruiter/Dashboard/Guides/MyPositionReport";
import Loading from "components/OldDesign/Loading";

// Define types for your store state
interface StoreState {
  appLanguage: string;
  getContent: (params: { page: string; language: string }) => void;
  content: {
    isLoaded: boolean;
  };
  detectOs: () => void;
  user: {
    data: {
      userCoreCvStatus: string;
      languageSetting: string;
      userType: string;
    };
  };
}

// Define type for location state
interface LocationState {
  previousLocation?: Location;
}

const App: React.FC = () => {
  const appLanguage = useStore((state: StoreState) => state.appLanguage);
  const getContent = useStore((state: StoreState) => state.getContent);
  const content = useStore((state: StoreState) => state.content);
  const detectOs = useStore((state: StoreState) => state.detectOs);
  const user = useStore((state: StoreState) => state.user.data);

  const location = useLocation();
  const previousLocation = (location.state as LocationState)?.previousLocation;

  const isMember = user.userCoreCvStatus === "completed";

  useEffect(() => {
    detectOs();

    const params = { page: "all", language: appLanguage };
    getContent(params);
    
  }, [detectOs, user.languageSetting, appLanguage, getContent]);

  if (!content.isLoaded) {
    return <Loading className={"h-screen w-screen"} />;
  }
  return (
    <>
      <Routes location={previousLocation || location}>
        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}

        {/* &&&&&&&&&& */}
        {/* AUTH PAGES */}
        {/* &&&&&&&&&& */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<ChooseUserType />} />
          <Route path="/register/candidate" element={<CandidateSignUp />} />
          <Route path="/register/recruiter" element={<RecruiterSignUp />} />
          <Route
            path="/register/password"
            element={
              <ProtectedAuthRoute allowedAction={["redirect"]}>
                <PasswordSet />
              </ProtectedAuthRoute>
            }
          />
          {/* <Route path="/register/verification" element={<PhoneVerification />} /> */}
          <Route
            path="/register/activation"
            element={
              <ProtectedAuthRoute allowedAction={["redirect", "email_auth"]}>
                <CodeActivation />
              </ProtectedAuthRoute>
            }
          />
          {/* <Route path="/login/2fa" element={<TwoFactorAuth />} /> */}
          <Route
            path="/login/password"
            element={
              <ProtectedAuthRoute allowedAction={["proceed_login"]}>
                <PasswordLogin />
              </ProtectedAuthRoute>
            }
          />
          <Route
            path="/resetpassword"
            element={
              // <ProtectedAuthRoute allowedAction={["proceed_reset"]}>
              <PasswordReset />
              // </ProtectedAuthRoute>
            }
          />
          <Route path="/metacv" element={<MetaCvDialogue />} />
          <Route path="/lazyguru" element={<LazyGuruDialogue />} />
        </Route>

        {/* &&&&&&&&&&&&&&& */}
        {/* DASHBOARD PAGES */}
        {/* &&&&&&&&&&&&&&& */}
        <Route element={<DashboardLayout />}>
          <Route path="/*" element={<Navigate to="/dashboard" replace />} />

          <Route
            path="/logout"
            element={
              <ProtectedRoute>
                <Logout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute allowedUserTypes={["candidate", "recruiter"]}>
                <Settings />
              </ProtectedRoute>
            }
          >
            {user.userType === "candidate" ? (
              <>
                <Route index element={<Navigate to="user" replace />} />
                <Route path="account" index element={<CandidateAccountSettings />} />
                <Route path="user" element={<CandidateProfileSettings />} />
              </>
            ) : (
              <>
                <Route index element={<Navigate to="user" replace />} />
                <Route path="company" index element={<CompanyProfileSettings />} />
                <Route path="user" element={<RecruiterProfileSettings />} />
              </>
            )}
          </Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute allowedUserTypes={["recruiter"]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mynetwork"
            element={
              <ProtectedRoute allowedUserTypes={["recruiter"]}>
                <MyNetwork />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="connections" replace />} />
            <Route path="connections" element={<Connections />} />
            <Route path="invitees" element={<Invitees />} />
            <Route path="groups" element={<MyGroups />} />
            <Route path="pool" element={<Pool />} />
            <Route path="reports" element={<Reports />} />
          </Route>
          <Route
            path="/overview"
            element={
              <ProtectedRoute allowedUserTypes={["candidate"]}>
                <Overview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/community"
            element={
              <ProtectedRoute>
                <Community />
              </ProtectedRoute>
            }
          />
          {/* CNA */}
          <Route
            path="/cna"
            element={
              <ProtectedRoute allowedUserTypes={["recruiter"]}>
                <CNA />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<CNAMainPage />} />
            <Route path="ideals" element={<MyJobPositions />} />
            <Route path="userreports" element={<UserReports />} />
            <Route path="positionreport" element={<JobPosReports />} />
            <Route path="aid" element={<HumanasIds />} />
          </Route>

          <Route
            path="/cna/my-positions/:id"
            element={
              <ProtectedRoute allowedUserTypes={["recruiter"]}>
                <JobPositionResult />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cna/my-analyses"
            element={
              <ProtectedRoute allowedUserTypes={["recruiter"]}>
                <MyAnalyses />
              </ProtectedRoute>
            }
          />

          <Route
            path="/aid"
            element={
              <ProtectedRoute>
                <HumanasAid />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="summary" replace />} />
            <Route path="summary" element={<AidSummaryPage />} />
            <Route path="identities" element={<AidIDsPage />} />
            <Route path="cna" element={<AidCNAPage />} />
            <Route path="services" element={<AidServicesPage />} />
          </Route>
          <Route
            path="/aid/identities/:aid"
            element={
              <ProtectedRoute>
                <AidPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<AidOverviewSubPage />} />
            <Route path="sectors" element={<AidSectorsSubPage />} />
            <Route path="interview" element={<AidInterviewSubPage />} />
            <Route path="features" element={<AidCharacteristicSubPage />} />
          </Route>

          {!isMember &&
            (IsMobile() ? (
              <Route path="/apply" element={<MobileApplicationForm />} />
            ) : (
              <Route path="/apply" element={<ApplicationForm />}>
                <Route index element={<Navigate to="step1" replace />} />
                <Route path="step1" element={<UserProfileForm />} />
                <Route path="step2" element={<JobDescriptionForm />} />
                <Route path="step3" element={<ExperienceForm />} />
                <Route path="step4" element={<EducationalStatusForm />} />
                <Route path="step5" element={<LanguageLevelForm />} />
                <Route path="confirmation" element={<PhoneConfirmationForm />} />
              </Route>
            ))}
        </Route>

        {/* &&&&&&&&&&&&&&& */}
        {/* CAMPAIGN PAGES  */}
        {/* &&&&&&&&&&&&&&& */}
        <Route path="campaign" element={<SharePage />} />
        <Route path="CompanyAutoLogin" element={<CompanyAutoLogin />} />

        {/* &&&&&&&&&&&&&&& */}
        {/* PRICING    PAGE */}
        {/* &&&&&&&&&&&&&&& */}
        <Route
          path="/pricing"
          element={
            <ProtectedRoute allowedUserTypes={["recruiter"]}>
              <Pricing />
            </ProtectedRoute>
          }
        />

        {/* &&&&&&&&&&&&&&& */}
        {/* DOWNLOAD METACV */}
        {/* &&&&&&&&&&&&&&& */}
        <Route path="downloadmetacv" element={<DownloadMetaCv />} />
        {/* &&&&&&&&&&&&&&& */}
        {/* DOWNLOAD LAZY GURU */}
        {/* &&&&&&&&&&&&&&& */}
        <Route path="/download-lazy-guru" element={<PlayLazyGuru />} />
      </Routes>
      {/* &&&&&&&&&&&&&&& */}
      {/* &&& MODALS &&&& */}
      {/* &&&&&&&&&&&&&&& */}
      {previousLocation && (
        <Routes>
          <Route
            path="/support"
            element={
              <Modal showModal={true}>
                <SupportForm />
              </Modal>
            }
          />
          <Route
            path="/join-community"
            element={
              <Modal showModal={true}>
                <JoinCommunityPopup />
              </Modal>
            }
          />
          <Route
            path="/start-a-discussion"
            element={
              <Modal showModal={true}>
                <StartADiscussionForm />
              </Modal>
            }
          />
          <Route
            path="/invite"
            element={
              <Modal showModal={true}>
                <InviteForm />
              </Modal>
            }
          />
          <Route
            path="/create-group"
            element={
              <Modal showModal={true}>
                <CreateGroupForm />
              </Modal>
            }
          />
          <Route
            path="/match"
            element={
              <Modal showModal={true}>
                <MatchGroups />
              </Modal>
            }
          />
          <Route
            path="/group_report/:id"
            element={
              <Modal showModal={true}>
                <GroupReport />
              </Modal>
            }
          />
          <Route
            path="/invite-success"
            element={
              <Modal showModal={true}>
                <InviteFormSuccessPopup>""</InviteFormSuccessPopup>
              </Modal>
            }
          />
          <Route
            path="/view/:id"
            element={
              <Modal showModal={true}>
                <MetaCvView />
              </Modal>
            }
          />
          <Route
            path="/analysis/:id"
            element={
              <Modal showModal={true}>
                <AnalysisReport />
              </Modal>
            }
          />
          <Route
            path="/add-position"
            element={
              <Modal showModal={true}>
                <AddJobPosition />
              </Modal>
            }
          />

          <Route
            path="/jobposition/:id"
            element={
              <Modal previousLocation={previousLocation} showModal={true}>
                <JobPositionAnalysis />
              </Modal>
            }
          >
            <Route path="home" element={<AnalysisHomePage />} />
            <Route path="tournament" element={<OrientationTournament />} />
            <Route path="corecv" element={<CorecvForm />} />
          </Route>
          <Route
            path="/guide"
            element={
              <Modal previousLocation={previousLocation} showModal={true}>
                <GuideModal />
              </Modal>
            }
          >
            <Route path="metacv/1" element={<WhatIsMetaCV />} />
            <Route path="metacv/2" element={<HowMetaCVCreated />} />
            <Route path="network/1" element={<MyConnections />} />
            <Route path="network/2" element={<MyGroupsGuide />} />
            <Route path="network/3" element={<Match />} />
            <Route path="aid" element={<AIDGuide />} />
            <Route path="cna/1" element={<CNAGuide />} />
            <Route path="cna/2" element={<IdealProfile />} />
            <Route path="cna/3" element={<IndividualCompatibilityReport />} />
            <Route path="cna/4" element={<MyPositionReport />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default App;
