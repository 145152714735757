import React, { forwardRef, Fragment } from "react";
import { TagWithIcon } from "../Tags";
import {
  BagIconFilled,
  BirthdayFilled,
  EmployeeFilled,
  LocationFilled,
  MailFilled,
  PhoneFilled,
  SendFilled,
} from "../../OldDesign/Icons";
import { Link } from "react-router-dom";
import Avatar from "../Avatars/Avatar";
import Button from "../Button/Button";
import { useStore } from "../../../store/useStore";
import Tooltip from "../Tooltips/Tooltip";
import { Wordcloud } from "@visx/wordcloud";
import OrientationSummary from "../OrientationSummary";
import useIsMobile from "../../../utils/useIsMobile";

import { User, WordCloudItem } from "types/user";
import { SectionId } from "./MetaCvView";

interface CvHeaderProps {
  handleScrollToSection?: (id: SectionId) => void;
  user: User;
}

interface ProcessedWordCloudItem extends WordCloudItem {
  x: number;
  y: number;
  size: number;
  font: string;
  rotate: number;
}

const CvHeader = forwardRef<HTMLDivElement, CvHeaderProps>(({ user, handleScrollToSection }, ref) => {
  return (
    <div id="header" ref={ref} className={`grid grid-cols-2 gap-4 pt-6 max-lg:grid-cols-1 max-lg:pt-2`}>
      <CoreCvBox user={user} handleScrollToSection={handleScrollToSection} />
      <AIDBox user={user} handleScrollToSection={handleScrollToSection} />
      <PersonalityBox user={user} />
      <OrientationBox user={user} handleScrollToSection={handleScrollToSection} />
    </div>
  );
});

const CoreCvBox: React.FC<CvHeaderProps> = ({ user, handleScrollToSection }) => {
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:hidden max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Core CV</h4>
        <Button
          onClick={() => handleScrollToSection("corecv")}
          className={"border-none bg-transparent p-0 text-primary-600 max-lg:hidden max-lg:p-0"}
        >
          Detayları gör
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <Avatar user={user} />
          <div className="inline-flex flex-1 flex-col gap-1">
            <p className="text-3xl font-bold leading-tight text-primary-900">{user.basic.name[0]?.name}</p>
            <p className="text-xl font-medium leading-tight text-neutral-700">{user.work.job[0]?.name}</p>
          </div>
        </div>
        <ul className="flex flex-wrap">
          <li className="basis-2/3 pb-2 text-sm leading-tight text-primary-900">
            <MailFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">{user.communications.email[0]?.name}</span>
          </li>
          <li className="basis-1/3 pb-2 text-sm leading-tight text-primary-900">
            <LocationFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">
              {user.basic.state[0]?.name || "n/a"}, {user.basic.country[0]?.name || "n/a"}
            </span>
          </li>
          <li className="basis-2/3 text-sm leading-tight text-primary-900">
            <PhoneFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">{user.communications.phone[0]?.name || "n/a"}</span>
          </li>
          <li className="basis-1/3 text-sm leading-tight text-primary-900">
            <BirthdayFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">{user.basic.birth_date[0]?.name || "n/a"}</span>
          </li>
        </ul>
      </div>
      <hr className="mt-auto"></hr>
      <div className="flex gap-3">
        <TagWithIcon
          icon={
            user.company.is_employee[0].id ? (
              <EmployeeFilled className="h-[14px] w-[14px]" />
            ) : (
              <SendFilled className="h-[14px] w-[14px]" />
            )
          }
          className={`bg-secondary_recruiter-100 px-2 py-1 text-secondary_recruiter-700`}
          text={user.company.is_employee[0].name}
        />
        <TagWithIcon
          icon={<BagIconFilled className="h-[14px] w-[14px]" />}
          className={`${
            user.work.work_state[0].id === "unemployed"
              ? "bg-success-100 text-success-400"
              : "bg-error-100 text-error-300"
          }  px-2 py-1 `}
          text={user.work.work_state[0].name}
        />
      </div>
    </div>
  );
};
const AIDBox: React.FC<CvHeaderProps> = ({ user, handleScrollToSection }) => {
  const strapiContent = useStore((state) => state.strapiContent);
  const _aid = strapiContent.data.find((aid) => aid.attributes.codeName === user.meta_cv.aid.id);
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Humanas Kimliği</h4>
        <Button
          onClick={() => handleScrollToSection("aid")}
          className={"border-none bg-transparent p-0 text-primary-600 max-lg:hidden max-lg:p-0"}
        >
          Detayları gör
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <Link to={`/aid/identities/${_aid.attributes.name}`}>
            <div
              style={{
                background: `linear-gradient(180deg, ${_aid.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
              }}
              className={`h-[100px] w-[100px] rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-[80px] max-lg:w-[80px]`}
            >
              <img
                src={process.env.REACT_APP_STRAPI_BASE_URL + _aid.attributes.logo.data.attributes.url}
                alt={_aid.attributes.name}
                title={_aid.attributes.name}
              />
            </div>
          </Link>
          <div className="inline-flex flex-1 flex-col gap-1">
            <p className="text-lg font-bold leading-tight text-primary-900">{_aid.attributes.name}</p>
            <p className="text-sm font-medium leading-tight text-neutral-900">{_aid.attributes.title}</p>
          </div>
        </div>
        <p className="text-sm leading-tight text-primary-900">{_aid.attributes.definition}</p>
      </div>
      <hr className="mt-auto"></hr>
      <div className="scrollbar-hide flex gap-3 overflow-x-auto max-lg:gap-1.5">
        {_aid.attributes.keyword.map((keyword, index) => (
          <TagWithIcon className={"bg-primary-75 text-primary-800"} key={index} text={keyword.keyword} />
        ))}
      </div>
    </div>
  );
};
const PersonalityBox: React.FC<CvHeaderProps> = ({ user }) => {
  const isMobile = useIsMobile();
  function showTooltip(evt, text) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left = evt.clientX > 150 ? evt.clientX - 130 + "px" : evt.clientX - 20 + "px";
    tooltip.style.top = evt.clientY + 10 + "px";
  }

  function hideTooltip() {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  }
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Kişilik Tipi</h4>
      </div>
      <div className="flex flex-col gap-2">
        <div className="mx-auto">
          <div
            id="tooltip"
            // display="none"
            className="fixed z-[999] hidden max-w-[360px] rounded-lg bg-primary-800 p-2 text-justify text-sm text-neutral-100"
          ></div>
          <Wordcloud
            words={user.meta_cv.word_cloud}
            fontSize={(word) => Math.sqrt(word.value)}
            padding={8}
            font={"Inter"}
            rotate={(word) => 0}
            spiral="archimedean"
            width={isMobile ? 325 : 527}
            height={isMobile ? 260 : 200}
            random={() => 0.5}
          >
            {(cloudWords: ProcessedWordCloudItem[]) =>
              cloudWords.map((word, index) => {
                return (
                  <Fragment key={index}>
                    <defs key={index}>
                      <filter x="-4%" y="-5%" width="108%" height="110%" id={index.toString()}>
                        <feFlood floodColor={word.bgColor} />
                        <feGaussianBlur stdDeviation="2" />
                        <feComponentTransfer>
                          <feFuncA type="table" tableValues="0 0 0 1" />
                        </feComponentTransfer>

                        <feComponentTransfer>
                          <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                        </feComponentTransfer>
                        <feComposite operator="over" in="SourceGraphic" />
                      </filter>
                    </defs>
                    <text
                      style={{
                        fontSize: word.size,
                        fontWeight: Math.log(word.value) * 100,
                        fill: word.color,
                        fontFamily: word.font,
                        cursor: "help",
                      }}
                      filter={`url(#${index})`}
                      textAnchor="middle"
                      transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                      onMouseMove={(e) => showTooltip(e, word.explain[0].description)}
                      onMouseLeave={hideTooltip}
                    >
                      {word.text}
                    </text>
                  </Fragment>
                );
              })
            }
          </Wordcloud>
        </div>
      </div>
      <hr className="mt-auto"></hr>
      <div className="flex flex-wrap justify-center gap-3">
        {user.meta_cv.upper_dimensions.map((upType, index) => {
          return (
            <Tooltip key={index} content={upType.description}>
              <div className="flex cursor-help items-center justify-center gap-1">
                <span className={`text-sm max-lg:text-[8px]`} style={{ color: upType.color }}>
                  {"\u2B24"}
                </span>
                <p className="text-sm">{upType.name}</p>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};
const OrientationBox: React.FC<CvHeaderProps> = ({ user, handleScrollToSection }) => {
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Mesleki Yönelim</h4>
        <Button
          onClick={() => handleScrollToSection("orientation")}
          className={"border-none bg-transparent p-0 text-primary-600 max-lg:hidden max-lg:p-0"}
        >
          Detayları gör
        </Button>
      </div>
      <div className="flex flex-1 flex-col items-center gap-4">
        <OrientationSummary summary={user.meta_cv.dimensions_stats} />
      </div>
    </div>
  );
};

export default CvHeader;
