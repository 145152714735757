import React, { forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import OrientationCard from "../OrientationCard";
import { User } from "types/user";

interface OrientationProps {
  user: User;
}

const ProfessionalOrientationSection = forwardRef<HTMLDivElement, OrientationProps>(({ user }, ref) => {
  return (
    <div id="orientation" ref={ref} className="flex flex-col gap-4">
      <SectionTitle title="Mesleki Yönelim" className="items-start gap-1 pb-2 text-[22px] max-lg:hidden" />
      {/* <p className="pb-9 leading-tight text-primary-900">
        Aşağıda mesleki yönelimlerim ile ilgili sonuçları bulabilirsiniz.
      </p> */}
      <div className="flex flex-col gap-6">
        {user.meta_cv.dimensions.map((orientation) => {
          // @ts-ignore
          // TODO: fix here after backend is updated
          return <OrientationCard key={orientation.title} orientation={orientation} />;
        })}
      </div>
    </div>
  );
});

export default ProfessionalOrientationSection;
