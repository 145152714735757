import React, { forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import { useStore } from "../../../store/useStore";
import { User } from "types/user";

interface CvInterviewQuestionsProps {
  user: User;
}

const CvInterviewQuestions = forwardRef<HTMLDivElement, CvInterviewQuestionsProps>(({ user }, ref) => {
  const strapiContent = useStore((state) => state.strapiContent);
  const _aid = strapiContent.data.find((aid) => aid.attributes.codeName === user.meta_cv.aid.id);
  return (
    <div id="questions" ref={ref} className="flex flex-col gap-4">
      <SectionTitle title={"Mülakat Soruları"} className={"items-start gap-1 text-[20px] max-lg:hidden"} />
      <div className="flex flex-col gap-3 rounded-lg bg-neutral-200 p-4 max-lg:bg-neutral-100 max-lg:p-2">
        <h4 className="text-lg font-semibold leading-tight text-secondary_recruiter-800">Temel Sorular</h4>
        {_aid.attributes.interviewQuestion
          .filter((item) => item.questionType === "Temel Soru")
          .map((question, index) => (
            <div
              key={index}
              className="flex flex-col gap-4 rounded-lg border border-neutral-300 bg-neutral-100 p-5 max-lg:bg-neutral-200"
            >
              <h5 className="font-medium leading-tight text-primary-900">{question.question}</h5>
              <p className="text-sm leading-tight text-neutral-800">{question.questionReason}</p>
            </div>
          ))}
        <h4 className="text-lg font-semibold leading-tight text-secondary_recruiter-800">Tamamlayıcı Sorular</h4>
        {_aid.attributes.interviewQuestion
          .filter((item) => item.questionType === "Tamamlayıcı Soru")
          .map((question, index) => (
            <div
              key={index}
              className="flex flex-col gap-4 rounded-lg border border-neutral-300 bg-neutral-100 p-5 max-lg:bg-neutral-200"
            >
              <h5 className="font-medium leading-tight text-primary-900">{question.question}</h5>
              <p className="text-sm leading-tight text-neutral-800">{question.questionReason}</p>
            </div>
          ))}
      </div>
    </div>
  );
});

export default CvInterviewQuestions;
