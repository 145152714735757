import React, { ReactNode } from "react";
import { CheckOutlined, ExclamationIcon, TimeOutlined } from "../../OldDesign/Icons";
import IsMobile from "../../../utils/IsMobile";
import { twMerge } from "tailwind-merge";
import Tooltip from "../Tooltips/Tooltip";

interface FieldProps {
  className?: string;
  children?: ReactNode;
  label?: string;
  error?: any;
  needs_approval?: boolean;
  is_approved?: boolean;
  [key: string]: any; // for ...props spread
}

export const Field = ({ className, children, label, error, needs_approval, is_approved }: FieldProps) => {
  const id = getChildId(children);
  const classes = "flex flex-col";
  const mergedClasses = twMerge(classes, className);
  return (
    <div className={mergedClasses}>
      <label
        htmlFor={id}
        className={`${error ? "text-error-400" : "text-neutral-900"} pb-1 text-[15px] leading-tight `}
      >
        {label}{" "}
        {needs_approval &&
          (is_approved ? (
            <Tooltip content={<span className="whitespace-nowrap">Onaylandı</span>}>
              <CheckOutlined className={"mb-0.5 inline-block h-4 w-4 text-success-400"} />
            </Tooltip>
          ) : (
            <Tooltip content={<span className="whitespace-nowrap">Onay Bekliyor</span>}>
              <TimeOutlined className={"mb-0.5 inline-block h-4 w-4 text-warning-400"} />
            </Tooltip>
          ))}
      </label>
      {children}
      {/* {!IsMobile() && error && (
        <small className="pt-1 text-error-400">
          <ExclamationIcon className="mb-1 mr-1 inline-block h-4 w-4" />
          {error.message}
        </small>
      )} */}
    </div>
  );
};

export const FieldCheckbox = ({ className, children, label, error }) => {
  const id = getChildId(children);
  const classes =
    "cursor-pointer flex flex-row items-center gap-2 rounded-lg border border-neutral-400 px-3 py-4 text-center text-[15px] leading-tight max-lg:px-2 max-lg:py-3";
  const mergedClasses = twMerge(classes, className);
  return (
    <>
      <label htmlFor={id} className={mergedClasses + `${error ? "text-error-400" : ""}`}>
        <div>{children}</div>
        {label}
      </label>
      {!IsMobile() && error && (
        <small className="pt-1 text-error-400">
          <ExclamationIcon className=" mb-1 mr-1 inline-block h-4 w-4" />
          {error.message}
        </small>
      )}
    </>
  );
};

// Get id prop from a child element
export const getChildId = (children: ReactNode) => {
  const child = React.Children.only(children) as React.ReactElement;

  if (child?.props && typeof child.props === "object") {
    return (child.props as { id?: string }).id;
  }
};
