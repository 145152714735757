import creategroup from "../../../../assets/guides/guide_groups.png";
import groups from "../../../../assets/guides/guide_groups2.png";
import groupreport from "../../../../assets/guides/guide_groupreport.png";
import groupmembers from "../../../../assets/guides/guide_groupmembers.png";
import matchorientation from "../../../../assets/guides/guide_matchorientation.png";

const MyGroups = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Grup Nasıl Oluşturulur?</h3>
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <p className="text-sm leading-tight text-neutral-800 lg:w-2/5">
            Grup oluşturma işlemi için öncelikle Ağım sayfasının sağ üst köşesinde bulunan <b>“Grupla”</b> butonuna
            tıklanmalıdır. Bu buton, seçilen kişileri bir grup içinde toplamasına olanak sağlar.
            <br />
            <br />
            Butona tıklandıktan sonra açılan pencerede grup adı, açıklama ve grup oluşturulacak kişilerin seçilmesi
            gerekir. Tüm alanlar tamamlandıktan sonra işlemi <b>“Grup Oluştur”</b> butonuna tıklayarak grubu başarıyla
            oluşturabilirsiniz.
          </p>
          <img src={creategroup} alt="creategroup" className="h-full object-cover max-lg:w-[300px] lg:w-3/5" />
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Gruplarım</h3>
        <div className="flex flex-col gap-4">
          <p className="text-sm leading-tight text-neutral-800">
            Gruplarım sekmesinde, daha önce oluşturduğunuz grupları görüntüleyebilirsiniz. Grup kartlarında grubunuzun
            adı ve oluşturma tarihi gibi detaylar yer almaktadır. Oluşturduğunuz grupları gerektiğinde düzenleyebilir
            veya silebilirsiniz.
          </p>
          <img src={groups} alt="groups" className="h-full w-full object-cover" />
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-xl bg-neutral-200 p-4">
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <div className="flex flex-col justify-center lg:w-2/5">
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">İnceleme Raporu</h3>
            <p className="text-sm leading-tight text-neutral-800">
              Bu alan, seçtiğiniz grubun ve grup içerisindeki kullanıcıların karşılaştırma skorlarını görüntülemenize
              olanak tanır.
              <br />
              <br />
              Grup üyelerinin uyum düzeylerini analiz ederek ekip içindeki güçlü yönleri ve gelişim alanlarını
              belirleyebilirsiniz. Raporu düzenleyebilir, kaydedebilir veya ihtiyaç duyduğunuzda rapor ekranını
              kapatarak değişiklikleri sonlandırabilirsiniz.
            </p>
          </div>
          <img src={groupreport} alt="groupreport" className="h-full object-cover max-lg:w-[300px] lg:w-3/5" />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Grup Raporu</h3>
            <p className="text-sm leading-tight text-neutral-800">
              Üye listesinden kişileri seçerek her birini oluşturulan grup ile kıyaslayabilirsiniz.
            </p>
          </div>
          <img src={groupmembers} alt="groupmembers" className="h-full w-full object-cover" />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Kişilik Tipi ve Mesleki Yönelim</h3>
            <p className="text-sm leading-tight text-neutral-800">
              Grupla seçtiğiniz kullanıcıların karşılaştırmalı şekilde kişilik tipi radar grafiği ve mesleki yönelim
              grafiklerini inceleyebilirsiniz. Bu sayede, kullanıcıların bireysel özelliklerini detaylı bir şekilde
              analiz etmenize ve grup içindeki uyum seviyelerini değerlendirmenize olanak tanır.
            </p>
          </div>
          <img src={matchorientation} alt="matchorientation" className="h-full w-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default MyGroups;
