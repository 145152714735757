import React from "react";
import userPP from "../../../user.png";

const Avatar = ({ user }) => {
  return (
    <div className="relative mb-2 inline-flex h-[93px] w-[93px] items-center justify-center rounded-full bg-[#069BF8] max-lg:h-[65px] max-lg:w-[65px]">
      <img
        src={user.basic.profile_picture[0].id || userPP}
        alt="profile"
        className="h-[85px] w-[85px] rounded-full max-lg:h-[57px] max-lg:w-[57px]"
      />
      <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-2.5 text-[13px]  font-semibold text-neutral-100 max-lg:px-1.5 max-lg:text-[11px]">
        LVL {user.basic.level[0].id}
      </span>
    </div>
  );
};

export default Avatar;
