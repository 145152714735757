import { FormEvent, forwardRef, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface FormProps {
  className?: string;
  children?: ReactNode;
  onSubmit: () => void;
  nextStep?: string;
  [key: string]: any; // for ...props spread
}

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ className, children, onSubmit, nextStep, ...props }, ref) => {
    const navigate = useNavigate();

    const onSubmitCustom = (e: FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onSubmit();
      if (nextStep) navigate(nextStep);
    };

    return (
      <form className={className} ref={ref} onSubmit={onSubmitCustom} {...props} noValidate>
        {children}
      </form>
    );
  },
);
