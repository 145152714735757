import invitations from "../../../../assets/guides/guide_invite.png";
import quickinvite from "../../../../assets/guides/guide_quickinvite.png";
import inviteform from "../../../../assets/guides/guide_inviteform.png";
import fileupload from "../../../../assets/guides/guide_fileupload.png";
import invitees from "../../../../assets/guides/guide_invitees.png";
import invitees2 from "../../../../assets/guides/guide_invitees2.png";

const HowMetaCVCreated = () => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm leading-tight text-neutral-800">
        Firma / kurum çalışanlarınızı ya da adaylarınızı davetiye ileterek sisteminize ekleyebilirsiniz. Davetiyeleri
        iletmek için “Ağım” alanındaki tüm sekmelerde bulunan “Davet Et” butonunu kullanabilirsiniz.
      </p>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Kullanıcı Daveti</h3>
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <p className="text-sm leading-tight text-neutral-800">
            İlk önce davetiyeyi kime ileteceğinizi seçiniz. Aday veya çalışanlarınızı davet ederek sisteminize hızlıca
            ekleyebilirsiniz. Davet yönteminizi belirlemek için aşağıdaki seçeneklerden birini kullanabilirsiniz.
          </p>
          <img src={invitations} alt="invitations" className="w-[220px] object-cover max-lg:w-[300px]" />
        </div>
      </div>

      <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
        <div className="graph-bg rounded-xl border border-neutral-300 bg-neutral-200 p-4">
          <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Hızlı Bağlantı</h3>
          <p className="pb-6 text-sm leading-tight text-neutral-800">
            Toplu davet yollamak için en hızlı yöntemdir. Kopyaladığınız bağlantıyı dilediğiniz kanallardan
            kullanıcılara iletebilirsiniz.
          </p>
          <img src={quickinvite} alt="quickinvite" className="w-[220px] object-cover max-lg:w-[300px]" />
        </div>
        <div className="graph-bg rounded-xl border border-neutral-300 bg-neutral-200 p-4">
          <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Davet Formu</h3>
          <p className="pb-6 text-sm leading-tight text-neutral-800">
            Toplu davet yollamak için en hızlı yöntemdir. Kopyaladığınız bağlantıyı dilediğiniz kanallardan
            kullanıcılara iletebilirsiniz.
          </p>
          <img src={inviteform} alt="inviteform" className="w-[220px] object-cover max-lg:w-[300px]" />
        </div>
        <div className="graph-bg rounded-xl border border-neutral-300 bg-neutral-200 p-4">
          <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Dosya Yükleme</h3>
          <p className="pb-6 text-sm leading-tight text-neutral-800">
            Kullanıcılarınızın mail adresleri ve isim soyisimlerinin olduğu dosyayı yönergeleri takip ederek
            ekleyebilirsiniz.
          </p>
          <img src={fileupload} alt="fileupload" className="w-[220px] object-cover max-lg:w-[300px]" />
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Davet Ettiklerim</h3>
        <div className="flex gap-4 pb-2 max-lg:flex-col max-lg:gap-2">
          <p className="text-sm leading-tight text-neutral-800">
            Sistem üzerinden davet ettiğiniz kullanıcıların durumunu takip edebilmenizi sağlar. Bu alanda, davet
            ettiğiniz kişilerin bilgileri görüntüleyebilirsiniz. Aynı zamanda, davetin hangi aşamada olduğunu kolayca
            kontrol edebilirsiniz. Bu özellik, davetlerinizi düzenli bir şekilde yönetmenize ve süreci takip etmenize
            yardımcı olur.
          </p>
          <img src={invitees} alt="invitees" className="h-full w-full object-cover" />
        </div>
        <img src={invitees2} alt="comments" className="object-cover" />
      </div>
    </div>
  );
};

export default HowMetaCVCreated;
