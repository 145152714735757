import cnaactionsuggestion from "../../../../assets/guides/guide_cnaactionsuggestion.png";
import cnaactionsuggestion2 from "../../../../assets/guides/guide_cnaactionsuggestion2.png";
import cnaactionsuggestion3 from "../../../../assets/guides/guide_cnaactionsuggestion3.png";
import cnaactionsuggestion4 from "../../../../assets/guides/guide_cnaactionsuggestion4.png";
import cnacorecvmatch from "../../../../assets/guides/guide_cnacorecvmatch.png";
import cnaorientationmatch from "../../../../assets/guides/guide_cnaorientationmatch.png";
import cnapositionreports from "../../../../assets/guides/guide_cnapositionreports.png";

const MyPositionReport = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-lg bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Pozisyon Raporu Nedir?</h3>
        <p className="text-sm text-neutral-800">
          Pozisyon gereklilikleriniz ile grup üyelerinizin uygunluklarını detaylı bir şekilde karşılaştırabilirsiniz. Bu
          alan, her bir üyenin <b>uyum skorları</b>nı, kişiye özel <b>aksiyon önerileri</b>ni, <b>Core CV</b> ve{" "}
          <b>mesleki yönelim</b>
          karşılaştırma sonuçlarını bir arada sunarak, pozisyon içerisinde derinleme bir analiz yapmanıza olanak sağlar.
        </p>
        <img src={cnapositionreports} alt="cna" className="object-cover" />
      </div>
      <div className="rounded-lg bg-neutral-200 p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
            <div className="lg:w-2/3">
              <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Çalışanlar İçin Aksiyon Önerileri</h3>
              <p className="text-sm text-neutral-800">
                Çalışanlarınızın performansını artırmak ve iş süreçlerini daha verimli hale getirmek amacıyla kişiye
                özel geliştirme ve iyileştirme önerileri sunar. Bu öneriler, çalışanlarınızın ihtiyaçlarına uygun
                çözümler sunarak, bireysel gelişimlerini desteklemeyi ve iş yerindeki verimliliklerini en üst düzeye
                çıkarmayı hedefler.
              </p>
            </div>
            <div className="max-lg:w-[300px] lg:w-1/3">
              <img src={cnaactionsuggestion} alt="cna" className="object-cover" />
            </div>
          </div>
          <div>
            <img src={cnaactionsuggestion2} alt="cna" className="object-cover" />
          </div>
          <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
            <div className="lg:w-2/3">
              <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Adaylar İçin Aksiyon Önerileri</h3>
              <p className="text-sm text-neutral-800">
                Adaylar için temel mülakat soruları ve iyileştirme önerileri sunulmaktadır. Bu öneriler, adayların
                yetkinliklerini daha iyi anlamanıza ve ihtiyaçlarına uygun aksiyonlar alarak işe alım sürecinizi daha
                etkili ve verimli hale getirmenize yardımcı olur.
              </p>
            </div>
            <div className="max-lg:w-[300px] lg:w-1/3">
              <img src={cnaactionsuggestion3} alt="cna" className="object-cover" />
            </div>
          </div>
          <div>
            <img src={cnaactionsuggestion4} alt="cna" className="object-cover" />
          </div>
          <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
            <div className="lg:w-1/2">
              <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Core CV</h3>
              <p className="text-sm text-neutral-800">
                Pozisyon için belirlediğiniz gereklilikler, bireyin sahip olduğu Core CV ile detaylı bir şekilde
                karşılaştırılmaktadır. Bu karşılaştırma sayesinde, pozisyon için kritik öneme sahip olan ve "Olmazsa
                Olmaz" olarak işaretlediğiniz maddeler açıkça vurgulanmakta, böylece seçtiniz adayın veya çalışanın bu
                kriterlere uygunluğunu hızlı ve etkin bir şekilde değerlendirebilirsiniz.
              </p>
            </div>
            <div className="max-lg:w-[300px] lg:w-1/2">
              <img src={cnacorecvmatch} alt="cna" className="object-cover" />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Mesleki Yönelim</h3>
              <p className="text-sm text-neutral-800">
                Pozisyonun gerektirdiği mesleki yönelimler ile bireyin sahip olduğu mesleki yönelimlerin detaylı bir
                şekilde karşılaştırılmasını yapabilir ve bu karşılaştırma sayesinde pozisyona uygunluk düzeyini daha net
                bir şekilde değerlendirebilirsiniz.
              </p>
            </div>
            <div>
              <img src={cnaorientationmatch} alt="cna" className="object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPositionReport;
