import { TagWithIcon } from "../../../../components/NewDesign/Tags";
import { CommentOutlined, DownloadOutlined, HeartFilled } from "../../../../components/OldDesign/Icons";
import wordcloud from "../../../../assets/guides/guide_wordcloud.png";
import corecv from "../../../../assets/guides/guide_corecv.png";
import meslekiyonelim from "../../../../assets/guides/guide_meslekiyonelim.png";
import comments from "../../../../assets/guides/guide_comments.png";
import aid from "../../../../assets/guides/guide_aid.png";
import aidcard1 from "../../../../assets/guides/aid_cardinfo-1.png";
import aidcard2 from "../../../../assets/guides/aid_cardinfo-2.png";
import aidcard3 from "../../../../assets/guides/aid_cardinfo-3.png";
import aidcard4 from "../../../../assets/guides/aid_cardinfo-4.png";

import { ORIENTATIONS } from "../../../../components/NewDesign/constants";
import { CurvedArrow } from "../../../../components/NewDesign/Illustrations";

const WhatIsMetaCV = () => {
  return (
    <div className="flex flex-col gap-4">
      {/* "Meta Cv Nedir?" Tab */}
      <p className="text-sm leading-tight text-neutral-800">
        Lazy Guru kullanıcılarının oyundaki yolculuklarının sonucunda ortaya çıkan ve kendi rızalarıyla oluşturdukları
        klasik CV ile, oyundaki aksiyonlarından elde edilen kişilik tipi, (mesleki yönelim) motivasyon, yetkinlik ve
        verimlilik tahminlerine dayanan güncel sonuç raporu.
      </p>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Aksiyonlar</h3>
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <div className="flex flex-1 items-center gap-2 rounded-lg border border-neutral-300 bg-neutral-100 p-2">
            <div className="rounded-md bg-primary-75 p-2">
              <DownloadOutlined className={"h-4 w-4 text-primary-500"} />
            </div>
            <div className="flex flex-col gap-1">
              <h4 className="text-sm font-bold">İndir</h4>
              <p className="text-[10px]">“İndir” butonuna basarak Meta CV’ yi .pdf formatında indirebilirsiniz.</p>
            </div>
          </div>
          <div className="flex flex-1 items-center gap-2 rounded-lg border border-neutral-300 bg-neutral-100 p-2">
            <div className="rounded-md bg-secondary_recruiter-200 p-2">
              <HeartFilled className={"h-4 w-4 text-secondary_recruiter-600"} />
            </div>
            <div className="flex flex-col gap-1">
              <h4 className="text-sm font-bold">Favorilere Ekle</h4>
              <p className="text-[10px]">“Kalp” butonuna basarak Meta CV’ yi “Favoriler” inize ekleyebilirsiniz.</p>
            </div>
          </div>
          <div className="flex flex-1 items-center gap-2 rounded-lg border border-neutral-300 bg-neutral-100 p-2">
            <div className="rounded-md bg-secondary_applicant-200 p-2">
              <CommentOutlined className={"h-4 w-4 text-secondary_applicant-800"} />
            </div>
            <div className="flex flex-col gap-1">
              <h4 className="text-sm font-bold">Yorum Yap</h4>
              <p className="text-[10px]">“Yorum” butonuna basarak dilediğiniz Meta CV’ ye yorum yazabilirsiniz.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Kişilik Tipi</h3>
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <p className="basis-1/2 text-sm leading-tight text-neutral-800">
            Kişilik tipi, bireyin kişilik özelliklerini <b>'Duygusal Dengeye Sahip'</b>, <b>'Sorumlu'</b>,{" "}
            <b>'Uyumlu'</b>, <b>'Dışa Dönük'</b> ve <b>'Deneyime Açık'</b> olmak üzere 5 farklı kategoride
            sınıflandırarak görselleştirir. Kelimelerin boyutları, bu özelliklerin bireyde ne kadar baskın olduğunu
            ifade ederken, renk kodları her özelliğin ait olduğu kategoriyi belirtir.
          </p>
          <div className="basis-1/2 rounded-xl border border-neutral-300 bg-neutral-100 py-2">
            <img src={wordcloud} alt="wordcloud" className="h-full object-cover" />
          </div>
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Core CV</h3>
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <p className="basis-1/2 text-sm leading-tight text-neutral-800">
            Kullanıcının eğitim geçmişi, lokasyonu, unvanı, pozisyon tecrübesi, yetenekleri ve çalışma tercihi gibi
            temel profesyonel verilerini içeren alandır. Bu bölüm, kullanıcının kariyer yolculuğunu ve profesyonel
            kimliğini detaylı bir şekilde tanıtır.
          </p>
          <div className="basis-1/2">
            <img src={corecv} alt="corecv" className="h-full object-cover" />
          </div>
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Mesleki Yönelim</h3>
        <div className="flex flex-col gap-4">
          <p className="text-sm leading-tight text-neutral-800">
            Bireyin sahip olduğu mesleki yönelimler detaylı bir şekilde analiz edilir. Toplamda 14 farklı mesleki
            yönelim bulunmakta olup, her biri açıklamalarıyla birlikte değerlendirilmek üzere sunulmaktadır. Bu analiz,
            bireyin potansiyelini daha iyi anlamanızı hedeflemiştir
          </p>
          <img src={meslekiyonelim} alt="meslekiyonelim" className="h-full object-cover" />
          <div className="flex flex-wrap justify-center gap-2.5">
            {ORIENTATIONS.map((orientation) => {
              return (
                <TagWithIcon
                  key={orientation.title}
                  className={"bg-neutral-100 text-sm font-medium text-neutral-700"}
                  text={orientation.title}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 rounded-xl bg-neutral-200 p-4">
        <div className="flex items-center rounded-xl border border-secondary_recruiter-300 bg-neutral-100 px-6 py-3 max-lg:flex-col">
          <div className="flex flex-col">
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Humanas Kimliği</h3>
            <p className="text-sm leading-tight text-neutral-800">
              Meta CV'leri daha verimli bir şekilde sınıflandırmak ve çalışan/adaylarınızın profesyonel özelliklerini
              detaylı bir şekilde analiz etmek için Humanas Akademi tarafından geliştirilen akıllı iş gücü
              profilleridir.
              <br />
              Her bir kimlik, özgün nitelikleriyle bireylerin potansiyellerini keşfetmek, kariyer yollarını
              şekillendirmek ve organizasyonel başarıyı artırmak için stratejik bir rehber niteliği taşır.
            </p>
          </div>
          <div className="basis-1/2 self-center">
            <img src={aid} alt="aid" className="h-full object-cover" />
          </div>
        </div>
        <div className="flex flex-wrap items-end justify-end max-lg:items-center max-lg:justify-center">
          <img src={aidcard4} alt="" className="object-cover max-lg:w-[155px] lg:w-[250px]" />
          <img src={aidcard1} alt="" className="-ml-4 object-cover max-lg:w-[155px] lg:w-[250px]" />
          <img src={aidcard2} alt="" className="-ml-5 object-cover max-lg:w-[155px] lg:w-[250px]" />
          <img src={aidcard3} alt="" className="object-cover max-lg:w-[155px] lg:w-[250px]" />
          <div className="flex-1 self-start max-lg:basis-full">
            <CurvedArrow className={"mx-auto"} />
            <p className="pl-4 text-sm leading-tight text-neutral-800">
              Bu bölüm, bireyin Humanas kimliğine uygun olarak <b>güçlü yönlerini</b>, <b>gelişime açık yönleri</b>,{" "}
              <b>zorlandığı durumları</b> ve <b>verimli olduğu durumları</b> analiz eder. Bu analiz, bireyin iş
              hayatındaki genel performansını değerlendirmek ve potansiyelini daha iyi anlamak için önemli ipuçları
              sunar.
            </p>
          </div>
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Yorumlar</h3>
        <div className="flex flex-col gap-4">
          <p className="text-sm leading-tight text-neutral-800">
            Dilediğiniz Meta CV’yi yorumlayabilir ve aynı zamanda o Meta CV’ye yazılan yorumları okuyarak ilgili kişinin
            profesyonel özelliklerini daha iyi analiz edebilirsiniz.
          </p>
          <img src={comments} alt="comments" className="object-cover" />
        </div>
      </div>
    </div>
  );
};

export default WhatIsMetaCV;
