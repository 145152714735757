import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import AsyncSelect from "react-select/async";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { LeftCircularOutlined } from "../../../OldDesign/Icons";
import { REACT_BASE_URL } from "../../../../api/constants";
import isPhoneNumValid from "../../../../utils/isPhoneNumValid";

const ContactInfoCard = (props) => {
  const appLanguage = useStore((state) => state.appLanguage);
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      user_phone: coreCV.phone || "",
      location_country: coreCV.location_country.country_id
        ? {
            country_id: coreCV.location_country.country_id,
            country_name: coreCV.location_country.country_name,
          }
        : "",
      location_state: coreCV.location_state.state_id
        ? {
            state_id: coreCV.location_state.state_id,
            state_name: coreCV.location_state.state_name,
          }
        : "",
    },
    mode: "onSubmit",
  });

  const sendFormData = async (formData) => {
    // formData.phone = formData.phone.replace(" ", "");
    // console.log(formData);

    await updateApplicationForm(formData, props.handleForwardForm);
  };

  const loadCountryOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/country?page=1&rows=20&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": appLanguage,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadCityOptions = async (value) => {
    const selectedCountry = getValues("location_country");
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/state?page=1&rows=60&keyword=${value}&country_id=${selectedCountry.country_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "X-localization": appLanguage,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  return (
    <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
      <fieldset className="flex flex-col gap-4">
        <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
          Contact Information
        </h2>
        <Field label={content["telefon numarasi baslik"]} error={errors?.user_phone}>
          <Controller
            control={control}
            name="user_phone"
            rules={{
              required: "Required",
              validate: (number) => isPhoneNumValid(number),
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <PhoneInput
                defaultCountry="tr"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disableFormatting
                forceDialCode
                inputClassName="border"
                className="border-1 rounded-lg border border-neutral-300  hover:border-primary-600 focus:border-4 focus:border-primary-600"
                inputProps={{
                  className: "pl-3 h-[49px] bg-neutral-100 text-[15px] lg:h-[52px] lg:text-[16px] outline-none",
                }}
                countrySelectorStyleProps={{
                  className: "bg-neutral-200 rounded-l-lg",
                  buttonClassName: "border-none h-full rounded-l-lg hover:rounded-l-lg px-3",
                }}
              />
            )}
          />
        </Field>
        <Field label={content["yasadiginiz ulke baslik"]} error={errors?.location_country}>
          <Controller
            control={control}
            name="location_country"
            rules={{
              required: "Required",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AsyncSelect
                cacheOptions={true}
                defaultOptions
                isClearable
                onBlur={onBlur}
                defaultInputValue={coreCV.location_country?.country_name || ""}
                onChange={(e) => onChange({ country_id: e?.id, country_name: e?.name })}
                loadOptions={loadCountryOptions}
                placeholder="Seçiniz"
                getOptionLabel={(opt) => opt.name}
                styles={{
                  placeholder: (styles, state) => ({
                    ...styles,
                    color: "#9A9ABA",
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    borderRadius: "8px",
                    borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                    borderWidth: "1px",
                    boxShadow: "none",
                  }),
                  valueContainer: (styles, state) => ({
                    ...styles,
                    height: "50px",
                    paddingLeft: "16px",
                  }),
                }}
              />
            )}
          />
        </Field>
        <Field label={content["yasadiginiz sehir baslik"]} error={errors?.location_state}>
          <Controller
            control={control}
            name="location_state"
            rules={{
              required: "Required",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AsyncSelect
                cacheOptions
                onBlur={onBlur}
                defaultInputValue={coreCV.location_state?.state_name || ""}
                onChange={(e) => onChange({ state_id: e.id, state_name: e.name })}
                selected={value}
                loadOptions={loadCityOptions}
                placeholder="Seçiniz"
                getOptionLabel={(opt) => opt.name}
                styles={{
                  placeholder: (styles, state) => ({
                    ...styles,
                    color: "#9A9ABA",
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    borderRadius: "8px",
                    borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                    borderWidth: "1px",
                    boxShadow: "none",
                  }),
                  valueContainer: (styles, state) => ({
                    ...styles,
                    height: "50px",
                    paddingLeft: "16px",
                  }),
                }}
              />
            )}
          />
        </Field>
      </fieldset>
      <div className="flex justify-center gap-20">
        <button disabled={isSubmitting} type="button" id="backwardArrow" onClick={() => props.handleBackwardForm()}>
          <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
        </button>
        <button disabled={isSubmitting} type="submit" id="forwardArrow">
          <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
        </button>
      </div>
    </Form>
  );
};

export default ContactInfoCard;
