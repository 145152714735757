import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { DrawnHeader1 } from "../../../components/NewDesign/DrawnHeader";
import { useStore } from "../../../store/useStore";

import userPP from "../../../user.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import Loading from "../../../components/OldDesign/Loading";
import { Link } from "react-router-dom";
import { ConnectionsWorld, WorldIllustration } from "../../../components/NewDesign/Illustrations";
import { getNetworkConnections } from "api/endpoints";
import { toast } from "react-toastify";
import useIsMobile from "utils/useIsMobile";

const AidOverviewSubPage = () => {
  const [AIDContent] = useOutletContext();
  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6 rounded-xl bg-neutral-100 p-9 max-lg:flex-col max-lg:gap-3 max-lg:p-4">
        <div className="flex flex-col gap-6 max-lg:gap-3">
          <DrawnHeader1 brushClassName={"-left-4 translate-x-0"} className={"max-lg:text-[20px]"}>
            {AIDContent.attributes.name}'ı
            <br />
            Yakından Tanıyalım!
          </DrawnHeader1>
          <p className="leading-tight text-primary-800 max-lg:text-[14px]">{AIDContent.attributes.description}</p>
        </div>
        <div
          style={{
            background: `linear-gradient(180deg, ${AIDContent.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
          }}
          className={`h-[210px] w-[210px] shrink-0 grow-0 rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:mx-auto max-lg:h-[160px] max-lg:w-[160px]`}
        >
          <img
            src={process.env.REACT_APP_STRAPI_BASE_URL + AIDContent.attributes.logo.data.attributes.url}
            alt={AIDContent.attributes.name}
          />
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-6 rounded-xl bg-neutral-100 p-9 max-lg:p-4">
        <DrawnHeader1 className={"text-center max-lg:text-[20px]"} brushClassName={""}>
          {AIDContent.attributes.name} Bağlantılarım
        </DrawnHeader1>

        <ConnectionsSwiper />
      </div>
    </div>
  );
};

const ConnectionsSwiper = () => {
  const [AIDContent] = useOutletContext();
  const isMobile = useIsMobile();
  const [connections, setConnections] = useState({
    isLoading: null,
    isLoaded: false,
    data: {},
    memberList: [],
    allUserCounts: {},
    paginationInfo: {},
  });
  const networkList = connections;

  useEffect(() => {
    const options = {
      filters: {
        badge: [AIDContent.attributes.codeName],
      },
      type: "connections",
      page: 1,
      rows: 200,
    };
    getNetworkConnections(
      options,
      null,
      (res) => {
        setConnections((prev) => ({
          ...prev,
          isLoading: false,
          isLoaded: true,
          data: res.data,
          memberList: res.data.rows,
          allUserCounts: res.data.search_metadata.filter_counts,
          paginationInfo: res.data.search_metadata.pagination_info,
        }));
      },
      (err) => {
        toast.error("Hata");
      },
      (bool) => {
        setConnections((prev) => ({
          ...prev,
          isLoading: bool,
        }));
      },
    );
    return () => {
      // clearNetworkList();
    };
  }, [AIDContent.attributes.codeName]);

  // console.log(networkList);

  let size = isMobile ? 2 : 6;
  const smallArrays = [];
  for (let i = 0; i < networkList.memberList.length; i += size) {
    smallArrays.push(networkList.memberList.slice(i, i + size));
  }
  console.log("render");

  const slides =
    networkList.memberList.length &&
    smallArrays.map((smallArray, index) => (
      <SwiperSlide key={index}>
        <div className="mx-16 grid min-h-0 min-w-0 grid-cols-3 grid-rows-2 justify-center gap-2 overflow-hidden max-lg:mx-8 max-lg:grid-cols-1 max-lg:grid-rows-1">
          {smallArray.map((user, index) => {
            return (
              <Link
                key={index}
                to="/mynetwork/connections"
                state={{
                  stateSelectedAidFilter: AIDContent.attributes.codeName,
                }}
              >
                <div
                  key={index}
                  className="flex items-center justify-center gap-2 rounded-xl border border-neutral-300 bg-neutral-100 px-4 py-2 max-lg:p-3"
                >
                  <div className="relative flex h-[55px] w-[55px] shrink-0 grow-0 items-center justify-center rounded-full bg-[#87C4FF] max-lg:h-[55px] max-lg:w-[55px]">
                    <img
                      src={user.basic.profile_picture[0].name}
                      alt="profile"
                      className="h-[48px] w-[48px] rounded-full max-lg:h-[48px] max-lg:w-[48px]"
                    />
                    <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[8px] font-semibold text-neutral-100">
                      LVL {user.basic.level[0].name}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 flex-col gap-0.5">
                    <p className="max-w-[calc(100%)] overflow-hidden text-ellipsis whitespace-nowrap font-medium leading-tight text-primary-900">
                      {user.basic.name[0].name}
                    </p>
                    <p className="max-w-[calc(100%)] overflow-hidden text-ellipsis whitespace-nowrap text-[13px] leading-tight text-neutral-900">
                      {user.work.job[0].name}
                    </p>
                    <p className="max-w-[calc(100%)] overflow-hidden text-ellipsis whitespace-nowrap text-[13px] leading-tight text-neutral-900">
                      {user.basic.state[0].name}, {user.basic.country[0].name}
                    </p>
                  </div>

                  <img
                    key={index}
                    src={process.env.REACT_APP_STRAPI_BASE_URL + AIDContent.attributes.badge0.data.attributes.url}
                    alt={AIDContent.attributes.name}
                    className="h-[35px] w-[35px]"
                  />
                </div>
              </Link>
            );
          })}
        </div>
      </SwiperSlide>
    ));

  // console.log(smallArrays);
  if (networkList.isLoading) {
    return <Loading className={"h-[300px]"} />;
  }

  if (!networkList.isLoaded || networkList.memberList.length === 0) {
    return null;
  }
  return (
    <>
      {networkList.isLoaded ? (
        <div className="max-h-screen min-h-0 w-full min-w-0 max-w-full">
          <Swiper
            style={{
              // "--swiper-navigation-color": "#000",
              "--swiper-navigation-size": isMobile ? "25px" : "36px",
            }}
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView="auto"
            navigation={true}
          >
            {slides}
          </Swiper>
        </div>
      ) : (
        <>
          <ConnectionsWorld className="mx-auto w-[500px] pb-9" />
          <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
            {AIDContent.attributes.name} bağlantınız bulunmamaktadır.
          </p>
        </>
      )}
    </>
  );
};

export default AidOverviewSubPage;
