import React, { useEffect, useState } from "react";
import Button from "../../../../components/NewDesign/Button/Button";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import SearchBar from "../../../../components/NewDesign/SearchBar/SearchBar";
import CircularProgressBar from "../../../../components/NewDesign/StatusBar/CircularProgressBar";
import { TagWithIcon } from "../../../../components/NewDesign/Tags";
import {
  BadgeFilled,
  BookFilled,
  ChangeFilled,
  CloseOutlined,
  DoubleTickOutlined,
  ExclamationIcon,
  MatchFilled,
  PeopleFilled,
  RightArrowOutlined,
  TagFilled,
  TickOutlined,
} from "../../../../components/OldDesign/Icons";
import { BulbIllustration } from "../../../../components/NewDesign/Illustrations";
import SectionTitle from "../../../../components/NewDesign/SectionTitle";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";
import IsMobile from "../../../../utils/IsMobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getJobPosition, getNetworkConnections } from "../../../../api/endpoints";
import Loading from "../../../../components/OldDesign/Loading";
import {
  COMPATIBILITY_STAT_COLORS,
  JOBPOS_CORECV_FIELD_KEYS,
  SCORE_TAGS,
} from "../../../../components/NewDesign/constants";
import MatchedOrientationCard from "../../../../components/NewDesign/MatchedOrientationCard";
import { useStore } from "../../../../store/useStore";
import Tooltip from "../../../../components/NewDesign/Tooltips/Tooltip";

const AnalysisReport = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [analysisReport, setAnalysisReport] = useState({ data: {}, isLoading: false, isLoaded: false });
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const matches = analysisReport.data.match;
  const isUserReport = location.state.reportType === "user";
  const currentMatch = currentMatchIndex >= 0 && matches[currentMatchIndex];

  const handleSearch = (e) => {
    setSearchKeyword(e.target.value);
  };
  // console.log(location.state);
  useEffect(() => {
    if (location.state.reportType === "user") {
      getNetworkConnections(
        null,
        `/${id}/match`,
        (res) => {
          setAnalysisReport((prev) => {
            return { ...prev, data: res.data, isLoaded: true };
          });
          const selectedIndex = res.data.match.findIndex((item) => item.info_target.id === location.state.posId);
          // console.log(location.state.posId, selectedIndex);
          setCurrentMatchIndex(selectedIndex > 0 ? selectedIndex : 0);
        },
        (err) => console.log(err),
        (bool) =>
          setAnalysisReport((prev) => {
            return { ...prev, isLoading: bool };
          }),
      );
    } else
      getJobPosition(
        null,
        `/${id}/match`,
        (res) => {
          setAnalysisReport((prev) => {
            return { ...prev, data: res.data, isLoaded: true };
          });
          setCurrentMatchIndex(0);
        },
        (err) => console.log(err),
        (bool) =>
          setAnalysisReport((prev) => {
            return { ...prev, isLoading: bool };
          }),
      );
  }, [id, location]);

  if (analysisReport.isLoading) {
    return <Loading />;
  }
  if (!analysisReport.isLoaded) {
    return null;
  }

  return (
    <div className="w-[1356px] bg-neutral-200 max-lg:w-screen">
      {/* header */}
      <div className="mb-6 flex items-center justify-between bg-neutral-100 px-9 py-4 max-lg:mb-3 max-lg:p-4 ">
        <h2 className="text-[26px] font-bold leading-tight text-primary-900 max-lg:text-[14px]">
          {currentMatch.info_reference.name} Uyum Raporu
        </h2>
        <Button onClick={() => navigate(-1)} className="bg-transparent text-primary-600">
          Kapat
        </Button>
      </div>
      <div className="relative flex gap-6 max-lg:flex-col lg:pl-9">
        {/* main */}
        <div className="relative flex basis-2/3 flex-col gap-4 max-lg:px-4">
          {/* versus cards */}
          <div className="flex gap-7 rounded-xl bg-primary-800 bg-cloudy bg-[200%] bg-repeat-x px-9 py-6 max-lg:gap-2 max-lg:p-3">
            <GradientBorder className="flex-1 bg-[linear-gradient(96deg,rgba(255,255,255,0.24)_2.41%,rgba(255,255,255,0.12)_99.5%)] p-4 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.25)] backdrop-blur-sm max-lg:max-w-[146px] max-lg:p-1.5">
              <div className="flex h-full flex-col items-start justify-between">
                <div className="flex items-center justify-start gap-1">
                  <img src={currentMatch.info_reference.picture} alt="" className="h-8 w-8 rounded-full" />
                  <p className="text-[18px] font-semibold leading-tight text-neutral-100 max-lg:text-[10px]">
                    {currentMatch.info_reference.name}
                  </p>
                </div>
                {!isUserReport && (
                  <TagWithIcon
                    className={"bg-transparent p-0 text-neutral-100 max-lg:text-[10px]"}
                    icon={<PeopleFilled className={"h-4 w-4 max-lg:h-3 max-lg:w-3"} />}
                    text={matches.length}
                  />
                )}
              </div>
            </GradientBorder>
            <MatchFilled className="w-14 text-primary-500 drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-lg:w-[24px]" />

            <GradientBorder className="flex-1 bg-[linear-gradient(96deg,rgba(255,255,255,0.24)_2.41%,rgba(255,255,255,0.12)_99.5%)] p-4 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.25)] backdrop-blur-sm max-lg:max-w-[146px] max-lg:p-1.5">
              <div className="flex h-full flex-col items-start justify-between gap-4 max-lg:gap-2">
                <div className="flex items-center gap-1">
                  <CircularProgressBar
                    textClassName={"text-neutral-100 font-bold"}
                    value={currentMatch.match_percent}
                    small
                  />
                  <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-[18px] font-semibold leading-tight text-neutral-100 max-lg:w-[92px] max-lg:text-[10px]">
                    {currentMatch.info_target.name}
                  </p>
                </div>
                <div className="relative">
                  <ChangeFilled className={"absolute h-5 w-5 text-neutral-100"} />
                  <select onChange={(e) => setCurrentMatchIndex(Number(e.target.value))} className="opacity-0">
                    {matches.map((match, index) => {
                      return <option value={index}>{match.info_target.name}</option>;
                    })}
                  </select>
                </div>
                {/* <TagWithIcon
                  className={"bg-transparent p-0 text-neutral-100 max-lg:text-[9px]"}
                  icon={<BlacklistOutlined className={"h-4 w-4 max-lg:h-3 max-lg:w-3"} />}
                  text={"Banliste Ekle"}
                /> */}
              </div>
            </GradientBorder>
          </div>
          {/* action recommendations */}
          <ActionsSuggestionsBox currentMatch={currentMatch} />
          {/* corecv compare table */}
          <div className="rounded-xl bg-neutral-100 p-6 max-lg:overflow-auto max-lg:p-4">
            <div className="flex items-center justify-between">
              <SectionTitle title={"Core CV"} className="items-start max-lg:text-base" />
              <Tooltip
                content={Object.keys(analysisReport.data.core_cv_states_content).map((key, index) => {
                  return (
                    <div className="flex flex-col items-start">
                      <CoreCvTag
                        key={index}
                        text={analysisReport.data.core_cv_states_content[key].description}
                        variant={index}
                      />
                    </div>
                  );
                })}
              >
                <TagWithIcon
                  className={"border border-secondary_recruiter-600 bg-opacity-0 text-secondary_recruiter-600"}
                  icon={<TagFilled className={"g-3 w-3"} />}
                  text={"Tag Info"}
                  // icon2={<ChevronDownOutlined className={"h-4 w-4"} />}
                />
              </Tooltip>
            </div>
            {/* <div className="overflow-x-auto"> */}
            <table className="w-full table-fixed border-separate border-spacing-x-6 max-lg:w-[660px] max-lg:border-spacing-x-3">
              <colgroup>
                <col className="w-1/5" />
                <col className="w-2/5 max-w-[290px] bg-neutral-200" />
                <col className="w-2/5 max-w-[290px] bg-primary-75" />
              </colgroup>
              <thead>
                <th></th>
                <th>
                  <p className=" py-6">{currentMatch.info_reference.name}</p>
                </th>
                <th>
                  <p className=" py-6">{currentMatch.info_target.name}</p>
                </th>
              </thead>
              <tbody>
                {Object.keys(JOBPOS_CORECV_FIELD_KEYS)
                  .filter((key) => !key.includes("about"))
                  .map((key, index) => {
                    const field = currentMatch.core_cv.find((item) => item.id === key);
                    return (
                      <tr key={index}>
                        <th className="border-b max-lg:text-start max-lg:text-sm">
                          <p>{JOBPOS_CORECV_FIELD_KEYS[key]}</p>
                        </th>
                        <td className="border-b">
                          <div className={`relative mx-6 flex flex-wrap gap-1  py-3`}>
                            {field && isUserReport
                              ? field.person.map((item, index) => (
                                  <span
                                    key={index}
                                    className="line-clamp-1 text-[13px] font-medium leading-tight text-primary-900"
                                  >
                                    {index !== 0 && <>&#x2022; </>}
                                    {item}
                                  </span>
                                ))
                              : field.job_position.map((item, index) => (
                                  <CoreCvTag key={index} text={item.name} variant={item.compatibility} />
                                ))}
                          </div>
                        </td>
                        <td className="border-b">
                          <div className={`${index === 0 && ""} flex flex-wrap gap-1 px-6 py-3`}>
                            {field && isUserReport
                              ? field.job_position.map((item, index) => (
                                  <CoreCvTag key={index} text={item.name} variant={item.compatibility} />
                                ))
                              : field.person.map((item, index) => (
                                  <span
                                    key={index}
                                    className="line-clamp-1 text-[13px] font-medium leading-tight text-primary-900"
                                  >
                                    {index !== 0 && <>&#x2022; </>}
                                    {item}
                                  </span>
                                ))}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* </div> */}
          </div>
          {/* radar chart */}
          {/* <div className="mx-auto h-[407px] w-[596px] max-lg:h-[220px] max-lg:w-[340px]">
            <RadarPersonalityChart radar_data={radar_data} />
          </div> */}
          {/* personality description */}
          {/* <div className="rounded-xl bg-neutral-100 px-6 py-3">
            <div className="flex items-center gap-1.5">
              <BulbIllustration className={"w-8"} />
              <h2 className="text-[22px] font-semibold text-secondary_recruiter-800">Kişilik Tipi Detayları</h2>

              <button onClick={() => setOpenActionsDrawer((prev) => !prev)} className="ml-auto">
                <ChevronDownOutlined
                  className={`${openActionsDrawer ? "rotate-180" : "rotate-0"} h-4 w-4 transition-all`}
                />
              </button>
            </div>
            <div
              className={`${
                openActionsDrawer ? "max-h-[700px]" : "max-h-0"
              } flex flex-col gap-2 overflow-auto transition-all duration-300 ease-in max-lg:text-[13px]`}
            >
              <div className="flex flex-col gap-4 max-lg:px-2 max-lg:py-4">
                {radar_data.map((personality, idx) => (
                  <div key={idx} className="rounded-xl bg-primary-50 p-4">
                    <h5 className="mb-2 border-l-2 border-primary-900 pl-1 text-[15px] font-semibold leading-tight text-primary-900">
                      {personality.title}
                    </h5>
                    <p className="text-[14px] font-medium leading-tight text-primary-800 max-lg:text-justify max-lg:text-[13px] max-lg:font-normal max-lg:text-neutral-800">
                      {personality.description || "N/A"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* professional orientation summary */}
          <div className="rounded-xl bg-neutral-100 px-6 py-3 max-lg:px-4">
            <SectionTitle title={"Mesleki Yönelim Özeti"} className="items-start max-lg:text-base" />
            <CompatibilitySummary summary={currentMatch.dimensions_compatibility} />
          </div>
          {/* professional orientations box */}
          <div className="rounded-xl bg-neutral-100 px-6 py-3 max-lg:p-3">
            <SectionTitle title={"Mesleki Yönelim Detayları"} className="items-start max-lg:text-base" />
            <div className="flex flex-col gap-2">
              {currentMatch.dimensions.map((dimension, index) => {
                return (
                  <MatchedOrientationCard
                    key={index}
                    refName={currentMatch.info_reference.name}
                    targetName={currentMatch.info_target.name}
                    orientation={dimension}
                    orientationContent={analysisReport.data.dimensions_content[dimension.id_combined]}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* members list */}
        <MembersBox
          matches={matches}
          handleSearch={handleSearch}
          searchKeyword={searchKeyword}
          currentMatchIndex={currentMatchIndex}
          setCurrentMatchIndex={setCurrentMatchIndex}
          isUserReport={isUserReport}
        />
      </div>
    </div>
  );
};

const MembersBox = ({
  matches,
  handleSearch,
  searchKeyword,
  currentMatchIndex,
  setCurrentMatchIndex,
  isUserReport,
}) => {
  const modalScrollY = useStore((state) => state.modalScrollY);
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const handleMemberTypeFilterChange = (type) => {
    if (memberTypeFilter === "" || memberTypeFilter !== type) {
      setMemberTypeFilter(type);
    } else if (memberTypeFilter === type) {
      setMemberTypeFilter("");
    }
  };
  return (
    <div
      className={`${
        modalScrollY > 160 && "sticky top-0"
      } flex max-h-screen basis-1/3 flex-col gap-4 self-start overflow-y-auto rounded-l-xl bg-neutral-100 p-6 max-lg:hidden`}
    >
      <div className="flex flex-col gap-2">
        <h3 className="text-xl font-bold leading-tight text-primary-900">Üyeler</h3>
        <p className="leading-tight text-neutral-700">Herhangi bir üyeyi seçin ve karşılaştırın!</p>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <SearchBar onChange={handleSearch} />
          {/* <FilterBar /> */}
        </div>
        <div className="flex gap-2">
          <TagWithIcon
            className={`${
              memberTypeFilter === "candidate"
                ? "bg-secondary_recruiter-600 text-neutral-100"
                : " bg-neutral-100 text-secondary_recruiter-600"
            }  cursor-pointer border border-secondary_recruiter-600`}
            text={`Aday \u00B7 ${matches.filter((match) => match.score_tag.type === "candidate").length}`}
            onClick={() => {
              handleMemberTypeFilterChange("candidate");
            }}
          />
          <TagWithIcon
            className={`${
              memberTypeFilter === "employee"
                ? "bg-secondary_recruiter-600 text-neutral-100"
                : " bg-neutral-100 text-secondary_recruiter-600"
            }  cursor-pointer border border-secondary_recruiter-600`}
            text={`Çalışan \u00B7 ${matches.filter((match) => match.score_tag.type === "employee").length}`}
            onClick={() => {
              handleMemberTypeFilterChange("employee");
            }}
          />
        </div>
      </div>
      <ul className="flex flex-col gap-3">
        {matches
          .filter((match) => match.info_target.name.toLowerCase().includes(searchKeyword.toLowerCase()))
          .filter((match) => match.score_tag.type.includes(memberTypeFilter))
          .map((match, index) => (
            <button onClick={() => setCurrentMatchIndex(index)} key={index}>
              <MatchRow
                isUserReport={isUserReport}
                match={match}
                isSelected={index === currentMatchIndex}
                variant={match.score_tag.id}
              />
            </button>
          ))}
      </ul>
    </div>
  );
};

const ActionsSuggestionsBox = ({ currentMatch }) => {
  const [drawers, setDrawers] = useState({
    basic: {
      isOpen: false,
      title: "Temel Sorular",
      textColor: "text-success-400",
      bgColor: "bg-success-100",
      icon: <BadgeFilled className="h-4 w-4 text-success-400" />,
    },
    complementary: {
      isOpen: false,
      title: "Tamamlayıcı Sorular",
      textColor: "text-card_candidate-400",
      bgColor: "bg-card_candidate-100",
      icon: <BookFilled className="h-4 w-4 text-card_candidate-400" />,
    },
    sustainability: {
      isOpen: false,
      title: "Geliştirme Önerileri",
      textColor: "text-success-400",
      bgColor: "bg-success-100",
      icon: <BadgeFilled className={`h-4 w-4 text-success-400`} />,
    },
    development: {
      isOpen: false,
      title: "İyileştirme Önerileri",
      textColor: "text-error-500",
      bgColor: "bg-error-100",
      icon: <BookFilled className="h-4 w-4 text-error-500" />,
    },
  });
  const handleOpenDrawer = (key) => {
    const newDrawers = { ...drawers };
    newDrawers[key].isOpen = !newDrawers[key].isOpen;
    setDrawers(newDrawers);
  };
  return (
    <div className="rounded-xl bg-neutral-100 px-6 py-3 max-lg:px-4">
      <div className="flex items-center gap-1.5">
        <BulbIllustration className={"w-8"} />
        <h2 className="text-[22px] font-semibold text-secondary_recruiter-800 max-lg:text-base">Aksiyon Önerileri</h2>

        <TagWithIcon
          className={`${SCORE_TAGS[currentMatch.score_tag.id].color} border border-neutral-700 bg-neutral-100`}
          icon={SCORE_TAGS[currentMatch.score_tag.id].icon}
          text={currentMatch.score_tag.name}
        />
      </div>
      <div className={`flex flex-col gap-2 overflow-auto transition-all duration-300 ease-in max-lg:text-[13px]`}>
        {Object.keys(drawers).map((key, index) => {
          const suggestions = currentMatch.action_suggestions.filter((item) => item.type === key);
          const suggestionsLength = currentMatch.action_suggestions.filter((item) => item.type === key).length;
          if (!drawers[key].isOpen) {
            suggestions.splice(2);
          }
          if (!suggestions.length) {
            return null;
          }
          return (
            <div
              key={index}
              onClick={() => handleOpenDrawer(key)}
              className={`${drawers[key].bgColor} ${
                !drawers[key].isOpen ? "action-suggestions-box cursor-pointer" : ""
              } flex flex-col gap-4 rounded-lg p-4 transition-all `}
            >
              <div className={`${drawers[key].textColor} flex items-center gap-1`}>
                <h4 className={`font-semibold leading-tight`}>{drawers[key].title}</h4>
                <TagWithIcon
                  className={`${drawers[key].textColor} bg-neutral-100 px-2 py-1`}
                  text={suggestionsLength}
                />
              </div>
              {suggestions.map((suggestion, index) => {
                return (
                  <div key={index} className="flex gap-1">
                    <div>{drawers[key].icon}</div>
                    <p className="text-sm leading-tight text-neutral-1000 text-opacity-90">{suggestion.description}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
        {/* <h4 className="font-semibold leading-tight text-primary-900">Öncelikli Tutundurma</h4> */}

        {/* {currentMatch.action_suggestions.filter((item) => item.type === "development").length > 0 && (
          <div className="flex flex-col gap-4 rounded-lg bg-error-100 p-4">
            {currentMatch.action_suggestions
              .filter((item) => item.type === "development")
              .map((suggestion, index) => {
                return (
                  <div key={index} className="flex gap-1">
                    <div>
                      <BookFilled className="h-4 w-4 text-error-500" />
                    </div>
                    <p className="text-sm leading-tight text-neutral-1000 text-opacity-90">{suggestion.description}</p>
                  </div>
                );
              })}
          </div>
        )} */}
      </div>
    </div>
  );
};

const MatchRow = ({ match, isSelected, variant, isUserReport }) => {
  return (
    <div
      className={`${
        isSelected ? "border-primary-500 bg-primary-75" : "border-neutral-300"
      } flex flex-col items-start gap-2 rounded-xl border p-3 transition-all`}
    >
      <div className="flex items-center gap-1">
        <CircularProgressBar value={match.match_percent} small />
        <p>{match.info_target.name}</p>
      </div>
      <div className="flex flex-wrap gap-1">
        <TagWithIcon
          className={`${SCORE_TAGS[variant].color} border border-neutral-700 bg-transparent font-medium`}
          icon={SCORE_TAGS[variant].icon}
          text={match.score_tag.name}
        />
        {!isUserReport && (
          <TagWithIcon
            className={`${
              match.score_tag.type === "employee"
                ? "bg-primary-75 text-primary-600"
                : "bg-secondary_applicant-100 text-secondary_applicant-600"
            } border border-neutral-700 font-medium`}
            text={match.score_tag.type === "employee" ? "Çalışan" : "Aday"}
          />
        )}
      </div>
    </div>
  );
};

const CompatibilitySummary = ({ summary }) => {
  const metacvDimStats = summary;
  const colors = COMPATIBILITY_STAT_COLORS;
  const RADIAN = Math.PI / 180;
  const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={13} fill="white" opacity="0.5" />
            <text x={x} y={y} fill="#0D568A" fontWeight={600} textAnchor={"middle"} dominantBaseline="central">
              {`${(percent * 14).toFixed(0)}`}
            </text>
          </>
        )}
      </>
    );
  };

  const handleScrollToOrientation = (id) => {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex items-center justify-center">
      <PieChart width={225} height={225}>
        <Pie
          data={metacvDimStats}
          dataKey={"value"}
          nameKey={"title"}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={customLabel}
          outerRadius={100}
          innerRadius={60}
        >
          {metacvDimStats.map((entry, index) => (
            <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
          ))}
        </Pie>
      </PieChart>
      <div className="flex w-[350px] flex-col divide-y">
        {metacvDimStats.map((entry, index) => (
          <div key={index} className="flex flex-1 items-center justify-between py-2">
            <p className="flex items-center pr-1 text-[17px] font-semibold leading-tight text-primary-700 max-lg:text-[14px]">
              <span className={`pr-2 text-[10px]`} style={{ color: colors[entry.id] }}>
                {"\u2B24"}
              </span>
              {entry.name}
            </p>
            <button
              // onClick={() => handleScrollToOrientation("orientationCard_" + entry.id)}
              disabled={entry.value === 0}
            >
              {entry.value} <RightArrowOutlined className={"inline-block h-4 w-4 text-primary-800"} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const CoreCvTag = ({ text, variant }) => {
  const variants = {
    0: "bg-card_candidate-250 text-neutral-700",
    1: "bg-secondary_applicant-200 text-secondary_applicant-600",
    2: "bg-neutral-600 text-neutral-100",
    3: "bg-secondary_applicant-600 text-neutral-100",
    4: "bg-primary-300 text-neutral-100",
    5: "bg-primary-400 text-neutral-100",
  };
  const icons = {
    0: <ExclamationIcon className={"h-4 w-4"} />,
    1: <TickOutlined className={"h-4 w-4"} />,
    2: <CloseOutlined className={"h-4 w-4"} />,
    3: <DoubleTickOutlined className={"h-4 w-4"} />,
    4: <CloseOutlined className={"h-4 w-4"} />,
    5: <TickOutlined className={"h-4 w-4"} />,
  };
  return (
    <TagWithIcon
      icon2={icons[variant]}
      text={text}
      className={`${variants[variant]} max-w-full items-start justify-start`}
    />
  );
};

const RadarPersonalityChart = ({ onlyRef, refName = "ref", targetName = "target" }) => {
  // onlyRef && radar_data.forEach((data) => delete data.value_target);
  const radar_data = [
    {
      title: "Duygusal Dengeye Sahip",
      description:
        "Duygusal denge kişilik sınıflandırması kişilerin genel olarak yaşantılarında (ev, iş, sosyal bağlam vb.) karşılaştığı durumlarda benimsedikleri yaklaşımları duygular ve duygu düzenleme açısından ele alan sınıflandırmadır. Bireyler karşılaştıkları zorlayıcı durumlarda rahat, uysal, iyimser veya soğukkanlı bir yaklaşımla davranışlarını şekillendirebilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 69,
      value_target: 74,
      value_max: 100,
    },
    {
      title: "Sorumlu",
      description:
        "Sorumlu kişilik sınıflandırması kişilerin genel olarak yaşantılarında (ev, iş, sosyal bağlam vb.) karşılaştığı durumlarda ne kadar sorumluluk üstlendikleri, hangi amaçla hareket ettikleri ve işlerini sürdürmede nasıl bir yaklaşım benimsedikleri ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda başarı odaklı, düzenli, öz-disiplinli, temkinli ve güvenilir kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 67,
      value_target: 67,
      value_max: 100,
    },
    {
      title: "Uyumlu",
      description:
        "Uyumlu kişilik sınıflandırması kişilerin genel olarak yaşantılarında (ev, iş, sosyal bağlam vb.) karşılaştığı durumlara ne kadar uyum gösterebildikleri ve nasıl uyum sağladıkları ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda duyarlı, uzlaşmacı ve alçakgönüllü kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 68,
      value_target: 72,
      value_max: 100,
    },
    {
      title: "Dışa Dönük",
      description:
        "Dışadönük kişilik sınıflandırması kişilerin sosyal ilişkilerinde kendilerini nasıl ifade ettikleri ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda baskın, sosyal ve enerjik kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 67,
      value_target: 59,
      value_max: 100,
    },
    {
      title: "Deneyime Açık",
      description:
        "Deneyime açık kişilik sınıflandırması kişilerin yeni deneyim edinme yaklaşımları ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda yaratıcı, estetik eğilimi olan, açık fikirli, iç görülü ve entellektüel kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 71,
      value_target: 66,
      value_max: 100,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart data={radar_data}>
        <defs>
          <linearGradient
            id="colorRefGroup"
            x1="148.912"
            y1="1.02734"
            x2="148.912"
            y2="255.707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6002EE" />
            <stop offset="1" stopColor="#C09AF9" stopOpacity="0.31" />
          </linearGradient>
          <linearGradient
            id="colorMatchGroup"
            x1="107.176"
            y1="1.47974"
            x2="107.176"
            y2="195.356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106BAC" />
            <stop offset="1" stopColor="#4064FF" stopOpacity="0" />
          </linearGradient>
        </defs>
        <PolarGrid />
        <PolarAngleAxis dataKey="title" fontWeight={600} fontSize={IsMobile() ? "13px" : "16px"} />
        {/* <PolarRadiusAxis domain={[0, 100]} /> */}
        <Radar
          dot={{ fillOpacity: 1, fill: "#6359E9" }}
          name={refName}
          dataKey="value_reference"
          stroke="#6359E9"
          fill="url(#colorRefGroup)"
          fillOpacity={0.3}
        />
        {!onlyRef && (
          <Radar
            dot={{ fillOpacity: 1, fill: "#9ECBF6" }}
            name={targetName}
            dataKey="value_target"
            stroke="#9ECBF6"
            fill="url(#colorMatchGroup)"
            fillOpacity={0.3}
          />
        )}
        <Legend
          iconType="circle"
          formatter={(value, entry, index) => (
            <span className="font-medium text-neutral-1000 max-lg:text-[13px]">{value}</span>
          )}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default AnalysisReport;
