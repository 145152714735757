import React, { useEffect, useState } from "react";
import SearchBar from "../../../../components/NewDesign/SearchBar/SearchBar";
import FilterBar from "../../../../components/NewDesign/FilterBar";
import SortBar from "../../../../components/NewDesign/SortBar";
import UserReportRow from "./UserReportRow";
import { useStore } from "../../../../store/useStore";
import Loading from "../../../../components/OldDesign/Loading";
import Pagination from "../../../../components/OldDesign/Pagination";
import emptyusers_aid from "../../../../assets/emptyusers_aid.png";
import { getNetworkConnections } from "api/endpoints";

const UserReports = () => {
  const [userReports, setUserReports] = useState({ data: [], isLoaded: false, isLoading: false });
  // PAGINATION
  const [pageSize, setPageSize] = useState(6); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page
  useEffect(() => {
    const options = {
      page: currentPage,
      rows: pageSize,
      filter: {
        job_position: ["any"],
      },
    };
    getNetworkConnections(
      options,
      null,
      (res) =>
        setUserReports((prev) => ({
          ...prev,
          data: res.data,
          isLoaded: true,
        })),
      (err) => console.log(err),
      (bool) =>
        setUserReports((prev) => ({
          ...prev,
          isLoading: bool,
        })),
    );
  }, [currentPage, pageSize]);
  // if (!userReports.isLoaded) {
  //   return null;
  // }
  return (
    <div className="flex h-full flex-col gap-4">
      {/* <div className="flex items-center gap-3">
        <SearchBar disabled />
        <SortBar disabled />
        <div className="ml-auto">
          <FilterBar />
        </div>
      </div> */}
      <div className="flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2 max-lg:hidden">
        <div className="basis-[30%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">İsim Soyisim</p>
        </div>
        <div className="basis-[30%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Pozisyon İsmi</p>
        </div>
        <div className="basis-[30%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Humanas Kimliği</p>
        </div>
        <div className="basis-[10%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
        </div>
      </div>
      <div className="-m-2 flex flex-col gap-4 overflow-y-auto p-2 lg:max-h-[calc(100%-162px)]">
        {userReports.isLoading ? (
          <Loading />
        ) : userReports.data.rows?.length ? (
          userReports.data.rows.map((user, index) => {
            return <UserReportRow user={user} key={index} />;
          })
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center gap-1">
            <img src={emptyusers_aid} alt="empty positions" />
            <p className="text-sm font-semibold text-primary-800">
              Henüz çalışanlarınızı bir iş pozisyonuna eklemediniz.
            </p>
          </div>
        )}
      </div>
      <div className="mt-auto">
        {userReports.data.rows?.length > 0 && (
          <Pagination
            dataLength={userReports.data.rows.length}
            setPageSize={setPageSize}
            currentPage={currentPage}
            totalCount={userReports.data.rows.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
};

export default UserReports;
