import { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import WhatIsCommunity from "./WhatIsCommunity";
import ApplicationFormOverview from "../../../components/NewDesign/ApplicationFormOverview";
import CompanyWeWork from "./CompanyWeWork";
import ApplyNow from "./ApplyNow";
import HeroCommunityCandidate from "../../../components/NewDesign/Hero/Community/HeroCommunityCandidate";
import Loading from "components/OldDesign/Loading";

const CommunityCandidate = () => {
  const user = useStore((state) => state.user.data);
  const coreCV = useStore((state) => state.coreCV);
  const getCoreCV = useStore((state) => state.getCoreCV);
  const isMember = user.userCoreCvStatus === "completed";
  const content = useStore(
    (state) => state.content.data["Candidate Community"].content.translations["Candidate Info Boxes"],
  );
  useEffect(() => {
    getCoreCV();
  }, [getCoreCV]);
  if (!coreCV.isLoaded) {
    return <Loading />;
  }
  return (
    <>
      <HeroCommunityCandidate joined={isMember} />
      {!isMember && <ApplicationFormOverview />}
      <WhatIsCommunity />
      <CompanyWeWork />
      {!isMember && <ApplyNow />}
      <div className="container2">
        <p className="py-9 text-[18px] font-medium leading-tight text-neutral-900 max-lg:py-3 max-lg:text-[13px]">
          {content["bottom page privacy 1"]}
          <a
            className="font-bold text-primary-600"
            href="https://humanas.io/protection-of-personal-data"
            target="_blank"
            rel="noreferrer"
          >
            {content["bottom page privacy 2"]}
          </a>
        </p>
      </div>
    </>
  );
};

export default CommunityCandidate;
