import { useEffect } from "react";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import {
  DotOutlined,
  HeartFilled,
  MagicFilled,
  PlusOutlined,
  RightArrowOutlined,
  SendFilled,
} from "../../../components/OldDesign/Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Guideline } from "../../../data/Guideline";
import Loading from "../../../components/OldDesign/Loading";
import { useStore } from "../../../store/useStore";
import Button from "../../../components/NewDesign/Button/Button";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getDashboard = useStore((state) => state.getDashboard);
  const dashboard = useStore((state) => state.dashboard);

  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  if (dashboard.isLoading) {
    return <Loading />;
  }
  if (!dashboard.isLoaded) {
    return null;
  }
  return (
    <>
      <section className="bg-neutral-200 max-lg:mb-[55px] max-lg:min-h-screen max-lg:bg-center max-lg:pt-[54px] lg:h-[calc(100dvh-64px)] lg:w-screen lg:overflow-hidden lg:bg-cover">
        <div className="container2 flex h-full gap-6 overflow-hidden max-lg:flex-col max-lg:gap-4">
          {/* NAVIGATION MENU */}
          <div className="flex h-full flex-none flex-col gap-4 overflow-y-auto bg-neutral-100 bg-opacity-50 px-3 py-6 max-lg:w-full max-lg:bg-transparent max-lg:px-0 max-lg:py-2 lg:w-[298px]">
            {/* WELCOMING */}
            <div className="flex flex-col gap-3 rounded-xl border border-neutral-300 bg-transparent p-4 max-lg:gap-2 max-lg:bg-neutral-100">
              <div className="flex items-start justify-between gap-2">
                <img
                  src={dashboard.data.profile_picture}
                  alt={dashboard.data.userName}
                  className="h-16 w-16 rounded-full"
                />
                <TagWithIcon
                  icon={<HeartFilled className="h-[14px] w-[14px]" />}
                  text={dashboard.data.badgeMessages[1]}
                  className="bg-neutral-100 px-0 font-bold text-primary-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="text-base font-semibold leading-tight text-primary-900">{dashboard.data.welcomeTitle}</p>
                <p className="text-sm font-normal leading-tight text-neutral-700">{dashboard.data.welcomeMessage}</p>
              </div>
              <hr />
              <div>
                <p className="text-sm font-bold leading-tight text-primary-900">{dashboard.data.companyName}</p>
              </div>
            </div>
            {/* NAVIGATION BUTTONS */}
            <div className="flex flex-col gap-2 max-lg:flex-row">
              <Link className="flex-1" to="/invite" state={{ previousLocation: location }}>
                <button className="flex w-full items-center justify-center gap-1 rounded-xl bg-[radial-gradient(143.14%_158.2%_at_104.39%_0%,#88A9FD_0%,#1F0877_100%)] p-3 text-neutral-100 max-lg:px-1.5 max-lg:text-[13px]">
                  <SendFilled className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
                  <span className="font-medium leading-tight">Davet Et</span>
                </button>
              </Link>
              <Link className="flex-1" to="/create-group" state={{ previousLocation: location }}>
                <button className="flex w-full items-center justify-center gap-1 rounded-xl bg-[radial-gradient(143.14%_158.2%_at_104.39%_0%,#51CBB6_0%,#223C3D_100%)] p-3 text-neutral-100 max-lg:px-1.5 max-lg:text-[13px]">
                  <PlusOutlined className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
                  <span className="font-medium leading-tight">Grupla</span>
                </button>
              </Link>
              <Link className="flex-1" to="/match" state={{ previousLocation: location }}>
                <button className="flex w-full items-center justify-center gap-1 rounded-xl bg-[radial-gradient(143.14%_158.2%_at_104.39%_0%,#1C8BD8_0%,#132A38_100%)] p-3 text-neutral-100 max-lg:px-1.5 max-lg:text-[13px]">
                  <MagicFilled className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
                  <span className="font-medium leading-tight">Match</span>
                </button>
              </Link>
            </div>
            {/* MINI SLIDER */}
            <div className="rounded-xl border border-neutral-300 bg-neutral-100 p-4 pb-6">
              <Slider {...sliderSettings}>
                <div>
                  <div className="flex flex-col items-start gap-3">
                    <h5 className="text-sm font-semibold leading-tight text-primary-900">Humanas Topluluğu</h5>
                    <p className="line-clamp-6 text-[13px] font-normal leading-tight text-primary-800 max-lg:line-clamp-3">
                      Yenilikçi sistemimiz, nitelikli adayları ve potansiyelli yetenekleri keşfetmenizi sağlar.
                    </p>
                    <Button
                      onClick={() => navigate("/community")}
                      className={"border-none bg-transparent p-1 text-primary-600"}
                      icon2={<RightArrowOutlined className={"h-4 w-4"} />}
                    >
                      Devamını Oku
                    </Button>
                  </div>
                </div>

                {/* <div>
                  <div className="flex flex-col items-start gap-3">
                    <h5 className="text-sm font-semibold leading-tight text-primary-900">Humanas Topluluğu</h5>
                    <p className="line-clamp-6 text-[13px] font-normal leading-tight text-primary-800 max-lg:line-clamp-3">
                      Topluluk mantığında kısaca bahsedeceğimiz ve detaylı bilgi için humanas.io’ya yönlenecek
                      kısayolların olduğu sayfa. Topluluk mantığında kısaca bahsedeceğimiz ve detaylı bilgi için
                      humanas.io’ya yönlenecek kısayolların olduğu sayfa. Topluluk mantığında kısaca bahsedeceğimiz ve
                      detaylı bilgi için humanas.io’ya yönlenecek kısayolların olduğu sayfa.
                    </p>
                    <Button
                      onClick={() => navigate("/community")}
                      className={"border-none bg-transparent p-1 text-primary-600"}
                      icon2={<RightArrowOutlined className={"h-4 w-4"} />}
                    >
                      Devamını Oku
                    </Button>
                  </div>
                </div>

                <div>
                  <div className="flex flex-col items-start gap-3">
                    <h5 className="text-sm font-semibold leading-tight text-primary-900">Humanas Topluluğu</h5>
                    <p className="line-clamp-6 text-[13px] font-normal leading-tight text-primary-800 max-lg:line-clamp-3">
                      Topluluk mantığında kısaca bahsedeceğimiz ve detaylı bilgi için humanas.io’ya yönlenecek
                      kısayolların olduğu sayfa.
                    </p>
                    <Button
                      onClick={() => navigate("/community")}
                      className={"border-none bg-transparent p-1 text-primary-600"}
                      icon2={<RightArrowOutlined className={"h-4 w-4"} />}
                    >
                      Devamını Oku
                    </Button>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
          {/* MAIN */}
          <div className="flex flex-1 flex-col gap-3 overflow-hidden py-6 max-lg:py-0">
            {/* GUIDES */}
            <div className="grid grid-cols-2 gap-4 overflow-y-auto max-lg:grid-cols-1">
              {Guideline.guides.map((guide, index) => {
                return <GuidelineCard key={index} guide={guide} />;
              })}
            </div>
            {/* TIPS */}
            <div className="flex flex-none flex-col gap-4">
              <h2 className="text-lg font-medium text-neutral-1000">Humanas’ta Sizi Neler Bekliyor?</h2>
              <div className="flex w-full items-center gap-2 overflow-x-auto pb-2">
                {Guideline.tips.map((tip, index) => {
                  return <TipCard key={index} tip={tip} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const GuidelineCard = ({ guide }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGuideModalOpen = () => {
    navigate(`/guide/${guide.sub.length > 0 ? guide.sub[0].path : guide.path}`, {
      state: { previousLocation: location },
    });
  };
  return (
    <div className="flex flex-row gap-2 overflow-clip rounded-xl border border-neutral-300 bg-neutral-100 p-4 max-lg:flex-col max-lg:p-4">
      <img src={guide.image} alt="" className="h-full object-cover lg:w-2/5 lg:rounded-xl" />
      <div className="flex h-full flex-col items-start">
        <div className="flex items-center gap-1 pb-1.5 text-neutral-700">
          <DotOutlined className={"h-2 w-2"} />
          <p className="line-clamp-2 text-[13px] font-bold">{guide.title}</p>
        </div>
        <h3 className="pb-3 text-lg font-bold leading-tight text-neutral-900">{guide.topic}</h3>
        <p className="line-clamp-3 text-sm font-medium leading-tight text-neutral-900">{guide.context}</p>
        <Button
          onClick={handleGuideModalOpen}
          className={"mt-auto border-none bg-transparent p-1 text-primary-600"}
          icon2={<RightArrowOutlined className={"h-4 w-4"} />}
        >
          {guide.buttonText}
        </Button>
      </div>
    </div>
  );
};

const TipCard = ({ tip }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGuideModalOpen = () => {
    navigate(`/guide/${tip.path}`, {
      state: { previousLocation: location },
    });
  };
  return (
    <div
      onClick={handleGuideModalOpen}
      className="flex min-w-[320px] cursor-pointer items-center gap-2 rounded-xl bg-secondary_recruiter-200 p-4"
    >
      <p className="font-semibold leading-tight text-neutral-900">{tip.description}</p>
      <div className="flex h-16 w-16 items-center justify-center rounded-md bg-neutral-100">{tip.icon}</div>
    </div>
  );
};

export default Dashboard;
