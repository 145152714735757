import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { ModalProps } from "../../types/general";

const Modal = ({ showModal, setShowModal, children, previousLocation }: ModalProps) => {
  const setModalScrollY = useStore((state) => state.setModalScrollY);
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observerRefVal = modalRef.current;
    if (observerRefVal) disableBodyScroll(observerRefVal);
    return () => {
      setModalScrollY(0);
      if (observerRefVal) {
        enableBodyScroll(observerRefVal);
      }
    };
  }, [setModalScrollY]);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setModalScrollY(e.currentTarget.scrollTop);
  };
  return (
    <>
      {showModal ? (
        <div ref={modalRef} onScroll={handleScroll} className="fixed inset-0 z-[1000] overflow-y-auto">
          <div
            className="fixed inset-0 h-full w-full bg-black opacity-50"
            onClick={() => {
              setShowModal ? setShowModal(false) : previousLocation ? navigate(previousLocation) : navigate(-1);
            }}
          ></div>
          <div className="flex min-h-screen items-center">
            <div className="relative mx-auto overflow-clip rounded-[10px] bg-white shadow-lg lg:mt-[3%]">
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
