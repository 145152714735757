import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Header from "../components/NewDesign/Header";
import Footer from "../components/NewDesign/Footer";
import { useStore } from "../store/useStore";
import MobileNavbar from "../components/NewDesign/MobileNavbar";
import IsMobile from "../utils/IsMobile";
import Loading from "../components/OldDesign/Loading";

const DashboardLayout = () => {
  const authenticated = useStore((state) => state.authenticated);
  const persistSession = useStore((state) => state.persistSession);
  const getStrapiContent = useStore((state) => state.getStrapiContent);
  const strapiContent = useStore((state) => state.strapiContent);

  const { pathname } = useLocation();
  const isCNAPage = pathname.slice(1, 4).includes("cna");
  const isDashboard = pathname.slice(1, 10).includes("dashboard");

  const navigate = useNavigate();
  const isApplicationForm = pathname.includes("apply");
  const hideConditions = isApplicationForm && IsMobile();

  useEffect(() => {
    if (!authenticated) {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        persistSession();
      } else {
        navigate("/login");
      }
    }
  }, [authenticated, persistSession, navigate]);

  useEffect(() => {
    getStrapiContent(
      {
        "populate[keyword][populate]": "*",
        "populate[situationCard][populate]": "*",
        "populate[featuringSector][populate]": "*",
        "populate[keyCharacteristic][populate]": "*",
        "populate[areas][populate]": "*",
        "populate[interviewQuestion][populate]": "*",
        "populate[logo][populate]": "*",
        "populate[badge0][populate]": "*",
      },
      "/humanas-ids",
    );
  }, [getStrapiContent]);

  if (!authenticated) {
    return null;
  }

  if (!strapiContent.isLoaded) {
    return <Loading className={"h-screen w-screen"} />;
  }

  if (strapiContent.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header hide={hideConditions} />
      <Outlet />
      <Footer hide={hideConditions || isCNAPage || isDashboard} />
      <MobileNavbar hide={hideConditions} />
    </>
  );
};

const TopScroller = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default DashboardLayout;
