import React, { forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import { useStore } from "../../../store/useStore";
import { Link } from "react-router-dom";
import { TagWithIcon } from "../Tags";
import { BadgeFilled, BookFilled, LightningFilled, StarFilled } from "../../OldDesign/Icons";
import { User } from "types/user";

interface CvAIDProps {
  user: User;
}

const CvAID = forwardRef<HTMLDivElement, CvAIDProps>(({ user }, ref) => {
  const strapiContent = useStore((state) => state.strapiContent);
  const _aid = strapiContent.data.find((aid) => aid.attributes.codeName === user.meta_cv.aid.id);
  return (
    <div id="aid" ref={ref} className="flex flex-col gap-4">
      <SectionTitle title={"Humanas Kimliği"} className={"items-start gap-1 text-[20px] max-lg:hidden"} />
      <div className="grid gap-4 max-lg:grid-cols-1 lg:grid-cols-2">
        <div className="flex items-center rounded-lg bg-[linear-gradient(247deg,#7986E8_1.26%,#36099F_98.95%)] p-4 max-lg:flex-col lg:col-span-full lg:gap-4">
          <Link to={`/aid/identities/`}>
            <div
              style={{
                background: `linear-gradient(180deg, ${_aid.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
              }}
              className={`h-[96px] w-[96px] rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-[80px] max-lg:w-[80px]`}
            >
              <img
                src={process.env.REACT_APP_STRAPI_BASE_URL + _aid.attributes.badge0.data.attributes.url}
                alt={_aid.attributes.name}
                title={_aid.attributes.name}
              />
            </div>
          </Link>
          <div className="flex flex-col gap-2">
            <div className="flex max-lg:flex-col max-lg:items-center lg:flex-1 lg:justify-between">
              <h3 className="text-lg font-semibold text-neutral-100">
                {_aid.attributes.name}, <span className="text-base font-normal">{_aid.attributes.title}</span>
              </h3>
              <div className="flex flex-wrap justify-center gap-3 overflow-x-auto max-lg:gap-1.5">
                {_aid.attributes.keyword.map((keyword, index) => (
                  <TagWithIcon
                    key={index}
                    className={"border border-neutral-100 bg-neutral-100 bg-opacity-20"}
                    text={keyword.keyword}
                  />
                ))}
              </div>
            </div>
            <p className="text-sm leading-tight text-primary-50">{_aid.attributes.description}</p>
          </div>
        </div>
        {_aid.attributes.areas.map((area, index) => (
          <div
            key={index}
            className={`relative flex flex-col gap-3 rounded-xl border border-neutral-300 p-6 max-lg:p-4 `}
          >
            <div className="flex items-center justify-start gap-2">
              <div className="rounded-full bg-secondary_recruiter-600 p-1">
                {index === 0 ? (
                  <BadgeFilled className="h-4 w-4 text-secondary_recruiter-100" />
                ) : (
                  <BookFilled className="h-4 w-4 text-secondary_recruiter-100" />
                )}
              </div>
              <h4 className="text-base font-semibold leading-tight text-primary-900">{area.title}</h4>
            </div>
            <hr />
            <ul className="flex flex-col gap-3">
              {area.Situation.map((item, index) => (
                <li key={index} className="text-sm leading-tight text-primary-800">
                  • <span className="font-semibold">{item.title};</span> {item.description}
                </li>
              ))}
            </ul>
          </div>
        ))}
        {_aid.attributes.situationCard.map((situation, index) => (
          <div
            key={index}
            className={`relative flex flex-col gap-3 rounded-xl border border-neutral-300 p-6 max-lg:p-4 `}
          >
            <div className="flex items-center justify-start gap-2">
              <div className="rounded-full bg-secondary_recruiter-600 p-1">
                {index === 0 ? (
                  <LightningFilled className="h-4 w-4 text-secondary_recruiter-100" />
                ) : (
                  <StarFilled className="h-4 w-4 text-secondary_recruiter-100" />
                )}
              </div>
              <h4 className="text-base font-semibold leading-tight text-primary-900">{situation.title}</h4>
            </div>
            <hr />
            <ul className="flex flex-col gap-3">
              {situation.Situation.map((situation, index) => (
                <li key={index} className="text-sm leading-tight text-primary-800">
                  • {situation.description}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <h5 className="text-lg font-semibold leading-tight text-secondary_recruiter-800">Öne Çıkan Sektörler</h5>
        <div className="grid grid-cols-2 gap-4 max-lg:grid-cols-1">
          {_aid.attributes.featuringSector.map((sector, index) => (
            <div
              key={index}
              className="relative flex flex-col gap-3 rounded-lg border border-l-8 border-secondary_recruiter-700 bg-neutral-100 p-4 max-lg:p-3"
            >
              <span className="text-base font-semibold text-primary-900">{sector.title}</span>
              <p className={`text-sm leading-tight text-neutral-800`}>{sector.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default CvAID;
