import React, { useState } from "react";
import Button from "../../../../components/NewDesign/Button/Button";
import { useStore } from "../../../../store/useStore";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "types/user";

interface UserReportRowProps {
  user: User;
}

const UserReportRow = ({ user }: UserReportRowProps) => {
  const [posId, setPosId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const strapiContent = useStore((state) => state.strapiContent);
  const aid = strapiContent.data.find((aid) => aid.attributes.codeName === user.meta_cv.aid.id).attributes;
  const openAnalysisReportModal = () =>
    navigate(`/analysis/${user.id}`, { state: { reportType: "user", previousLocation: location, posId: posId } });
  const handlePosIndexChange = (e) => {
    setPosId(e.target.value);
  };
  return (
    <div className="flex items-center justify-between rounded-lg border border-neutral-100 bg-secondary_recruiter-100 px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] max-lg:flex-wrap">
      <div className="flex basis-[30%] flex-col gap-1 max-lg:basis-[50%] max-lg:gap-0.5">
        <p className="font-medium leading-tight text-primary-800">{user.basic.name[0].id}</p>
        <p
          className={`${
            user.company.is_employee[0].id === "candidate"
              ? "text-secondary_applicant-600"
              : "text-secondary_recruiter-700"
          } text-xs font-medium leading-tight `}
        >
          {user.company.is_employee[0].name}
        </p>
      </div>
      <div className="basis-[30%] max-lg:basis-[50%]">
        <p className="font-medium leading-tight text-primary-800 max-lg:text-right max-lg:text-sm">
          <select onChange={handlePosIndexChange} className="bg-transparent text-sm font-medium">
            {user.company.job_positions.map((position, index) => (
              <option key={index} value={position.id}>
                {position.name}
              </option>
            ))}
          </select>
        </p>
      </div>
      <div className="flex basis-[30%] items-center justify-start gap-1 max-lg:basis-full max-lg:justify-end">
        <div
          style={{
            background: `linear-gradient(180deg, ${aid.primaryColor} 0%, #FFFFFF00 100%)`,
          }}
          className={`h-8 w-8 rounded-full shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-7 max-lg:w-7`}
        >
          <img src={process.env.REACT_APP_STRAPI_BASE_URL + aid.logo.data.attributes.url} alt={aid.name} />
        </div>
        <div className="flex flex-col gap-1">
          <h2 className={`font-semibold leading-tight text-neutral-800 max-lg:text-sm`}>{aid.name}</h2>
          <h2 className={`text-sm font-normal leading-tight text-neutral-800 max-lg:text-xs`}>{aid.title}</h2>
        </div>
      </div>
      <div className="basis-[10%] max-lg:basis-full">
        <Button
          // disabled
          onClick={openAnalysisReportModal}
          className="border-secondary_applicant-700 bg-secondary_applicant-100 px-6 py-2 text-secondary_applicant-700"
        >
          Görüntüle
        </Button>
      </div>
    </div>
  );
};

export default UserReportRow;
