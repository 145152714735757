import React from "react";
import aid from "../../../../assets/guides/guide_aid.png";
import aidcard1 from "../../../../assets/guides/aid_cardinfo-1.png";
import aidcard2 from "../../../../assets/guides/aid_cardinfo-2.png";
import aidcard3 from "../../../../assets/guides/aid_cardinfo-3.png";
import aidcard4 from "../../../../assets/guides/aid_cardinfo-4.png";
import { CurvedArrow, PurpleSmoke } from "components/NewDesign/Illustrations";
import aidVideo2 from "../../../../assets/aidHeroMov.mp4";
import interviewquestions from "../../../../assets/guides/guide_interviewquestions.png";
import sectors from "../../../../assets/guides/guide_sectors.png";
import uniqueprofiles from "../../../../assets/guides/guide_uniqueprofiles.png";
import balancedprofiles from "../../../../assets/guides/guide_balancedprofiles.png";
import graphs from "../../../../assets/guides/guide_graphs.png";

const AIDGuide = () => {
  return (
    <div className="flex flex-col gap-4">
      {/* <h2 className="text-lg font-semibold leading-tight text-neutral-900">Humanas AID</h2> */}
      <div className="relative overflow-hidden rounded-xl bg-black">
        <div className="relative flex items-center gap-4 max-lg:flex-col max-lg:gap-2">
          <div className="z-10 flex flex-col gap-1 p-4 py-3 max-lg:pb-20 lg:w-2/3">
            <h2 className="gradient-text inline-block animate-gradient text-2xl font-semibold leading-tight text-transparent">
              AID Nedir?
            </h2>
            <p className="text-xs leading-tight text-neutral-100">
              Humanas AID, iş süreçlerinde en çok ihtiyaç duyulan çalışan özelliklerinin derlenerek bir araya geldiği
              kimlikleri ifade etmektedir. Çalışan ve çalışan adaylarınızın mesleki yönelim /iş kimlikleri/iş
              karakterleri dağılımına göz atın ve hangi Humanas AID’lere sahip olduklarını görün.
            </p>
          </div>
          <div className="relative z-0 max-lg:w-full lg:w-1/3">
            <div className="absolute -translate-y-1/2 overflow-clip rounded-xl max-lg:-top-10 max-lg:left-0 lg:-right-4 lg:top-1/2">
              <video src={aidVideo2} type="video/mp4" autoPlay loop muted playsInline className="h-full w-full"></video>
            </div>
          </div>
        </div>
        <PurpleSmoke className={"absolute bottom-0 left-0 z-0 rotate-180"} />
        <PurpleSmoke className={"absolute right-0 top-0 z-0"} />
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4 rounded-xl border border-neutral-300 bg-neutral-100 p-4 max-lg:flex-col">
            <div className="flex flex-col">
              <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Humanas Kimliği</h3>
              <p className="text-sm leading-tight text-neutral-800">
                Meta CV'leri daha verimli bir şekilde sınıflandırmak ve çalışan/adaylarınızın profesyonel özelliklerini
                detaylı bir şekilde analiz etmek için Humanas Akademi tarafından geliştirilen akıllı iş gücü
                profilleridir.
                <br />
                Her bir kimlik, özgün nitelikleriyle bireylerin potansiyellerini keşfetmek, kariyer yollarını
                şekillendirmek ve organizasyonel başarıyı artırmak için stratejik bir rehber niteliği taşır.
              </p>
            </div>
            <div className="basis-1/2">
              <img src={aid} alt="aid" className="h-full object-cover max-lg:w-[200px]" />
            </div>
          </div>
          <div className="flex flex-wrap items-end justify-end max-lg:items-center max-lg:justify-center">
            <img src={aidcard4} alt="" className=" object-cover max-lg:w-[155px] lg:w-[250px]" />
            <img src={aidcard1} alt="" className="-ml-4  object-cover max-lg:w-[155px] lg:w-[250px]" />
            <img src={aidcard2} alt="" className="-ml-5  object-cover max-lg:w-[155px] lg:w-[250px]" />
            <img src={aidcard3} alt="" className=" object-cover max-lg:w-[155px] lg:w-[250px]" />
            <div className="flex-1 self-start max-lg:basis-full">
              <CurvedArrow className={"mx-auto"} />
              <p className="pl-4 text-sm leading-tight text-neutral-800">
                Bu bölüm, bireyin Humanas kimliğine uygun olarak <b>güçlü yönlerini</b>, <b>gelişime açık yönleri</b>,{" "}
                <b>zorlandığı durumları</b> ve <b>verimli olduğu durumları</b> analiz eder. Bu analiz, bireyin iş
                hayatındaki genel performansını değerlendirmek ve potansiyelini daha iyi anlamak için önemli ipuçları
                sunar.
              </p>
            </div>
          </div>
          <div>
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Adaylar için Mülakat Soruları</h3>
            <div className="flex flex-col gap-4">
              <p className="text-sm leading-tight text-neutral-800">
                Adaylar için mülakat sürecini kolaylaştırmak ve daha etkili sonuçlar elde etmek amacıyla hazırlanan iki
                ayrı kategorideki soruları bulabilirsiniz.
                <br />
                <b>Temel Sorular</b> kategorisi, adayların genel niteliklerini ve mesleki uygunluğunu anlamaya
                odaklanırken,
                <b>Tamamlayıcı Sorular</b> kategorisi ise adayların derinlemesine değerlendirilmesine yardımcı olacak
                ayrıntılı sorulardan oluşur. Bu iki kategori, işe alım sürecinizi daha yapılandırılmış ve verimli hale
                getirir.
              </p>
              <img src={interviewquestions} alt="" className="self-center object-cover max-lg:w-[250px] lg:w-1/2" />
            </div>
          </div>
          <div>
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Öne Çıkan Sektörler</h3>
            <div className="flex flex-col gap-4">
              <p className="text-sm leading-tight text-neutral-800">
                Aday ve çalışanlarınızın yeteneklerine en uygun sektörleri belirlemenize yardımcı olur. Bu alanda,
                farklı sektörlere dair öne çıkan kategorileri inceleyebilir ve hangi sektörlerin bireylerin profesyonel
                hedefleriyle örtüştüğünü değerlendirebilirsiniz.
              </p>
              <img src={sectors} alt="" className="self-center object-cover" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
        <div className="graph-bg rounded-xl bg-neutral-200 p-4">
          <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Sıradışı Profiller</h3>
          <p className="text-sm leading-tight text-neutral-800">
            Mesleki yöneliminde <b>sıradışı</b> yetkinlikler sergileyen bireylerin profillerini içerir.
          </p>
          <img src={uniqueprofiles} alt="" className="self-center object-cover max-lg:w-[250px]" />
        </div>
        <div className="graph-bg rounded-xl bg-neutral-200 p-4">
          <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Dengeli Profiller</h3>
          <p className="text-sm leading-tight text-neutral-800">
            Mesleki yöneliminde <b>dengeli</b> yetkinlikler sergileyen bireylerin profillerini içerir.
          </p>
          <img src={balancedprofiles} alt="" className="self-center object-cover max-lg:w-[250px]" />
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <div className="flex items-center gap-4 max-lg:flex-col max-lg:gap-2">
          <div className="flex-1 ">
            <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Grafikler</h3>
            <p className="text-sm leading-tight text-neutral-800">
              Aday ve çalışanlarınızın profillerine ait yönelim düzeyi, çalışma zamanı ve çalışma yeri gibi verileri
              grafikler aracılığıyla detaylı analiz etmenizi sağlar. Bunun yanı sıra, çalışan ve çalışmayan aday
              sayılarını ayrı ayrı görüntüleyerek iş gücü durumuna dair kapsamlı bir değerlendirme yapabilirsiniz.
            </p>
          </div>
          <div className="flex-1">
            <img src={graphs} alt="" className="h-full object-cover max-lg:w-[300px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIDGuide;
