import metacvPng from "../assets/guides/guidecard_metacv.png";
import networkPng from "../assets/guides/guidecard_network.png";
import humanasaidPng from "../assets/guides/guidecard_aid.png";
import cnaPng from "../assets/guides/guidecard_cna.png";
import { MetaCvOnlyLogo } from "../components/OldDesign/Icons";

export const Guideline = {
  guides: [
    {
      title: "MetaCV",
      topic: "Yolculuk Başlasın!",
      context: "Tüm bağlantılarınız için detay bilgilere ulaşın, dilediğiniz kadar grup oluşturup, karşılaştırın.",
      image: metacvPng,
      buttonText: "MetaCV’yi Keşfet",
      path: "metacv",
      sub: [
        {
          title: "MetaCV Nedir?",
          path: "metacv/1",
        },
        {
          title: "Nasıl Oluşur?",
          path: "metacv/2",
        },
      ],
    },
    {
      title: "Network",
      topic: "Güncel MetaCV Havuzunuz",
      context: "Tüm bağlantılarınız için detay bilgilere ulaşın, dilediğiniz kadar grup oluşturup, karşılaştırın.",
      image: networkPng,
      buttonText: "Network Keşfet",
      path: "network",
      sub: [
        {
          title: "Bağlantılarım",
          path: "network/1",
        },
        {
          title: "Gruplarım",
          path: "network/2",
        },
        {
          title: "Match",
          path: "network/3",
        },
      ],
    },
    {
      title: "Humanas AID",
      topic: "En çok ihtiyaç duyulan kimlikler!",
      context: "Çalışan ve adaylarınızı yakından tanıyın. ",
      image: humanasaidPng,
      buttonText: "Humanas AID’i Keşfet",
      path: "aid",
      sub: [],
    },
    {
      title: "CNA",
      topic: "Sizin normunuz sizin kararınız!",
      context:
        "İdealler, normlar, uyum skorları ve aksiyon önerileri. Firma özelinde yürütülen akademik çalışma raporları.",
      image: cnaPng,
      buttonText: "CNA’yi Keşfet",
      path: "cna",
      sub: [
        {
          title: "CNA",
          path: "cna/1",
        },
        {
          title: "İdeal Çalışan Profili",
          path: "cna/2",
        },
        {
          title: "Bireysel Uyum Raporu",
          path: "cna/3",
        },
        {
          title: "Pozisyon Raporlarım",
          path: "cna/4",
        },
      ],
    },
  ],
  tips: [
    {
      description: "Aday ve Çalışanlarınıza Davet Gönderin!",
      icon: <MetaCvOnlyLogo className={"w-16"} />,
      path: "metacv/2",
    },
    {
      description: "Örnek Meta CV’yi inceleyin!",
      icon: <MetaCvOnlyLogo className={"w-16"} />,
      path: "metacv/1",
    },
    {
      description: "Humanas Kimliklerini İncelediniz mi?",
      icon: <MetaCvOnlyLogo className={"w-16"} />,
      path: "aid",
    },
    {
      description: "İdeal Çalışan Profili Nasıl Oluşturulur?",
      icon: <MetaCvOnlyLogo className={"w-16"} />,
      path: "cna/2",
    },
  ],
};
