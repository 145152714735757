import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/NewDesign/Button/Button";
import {
  ChevronRightOutlined,
  CloseOutlined,
  ColumnsOutlined,
  CopyOutlined,
  EditOutlined,
  InfoOutlined,
  PeopleFilled,
  PlusOutlined,
  RubbishOutlined,
  ShareOutlined,
  TickOutlined,
  WaitingOutlined,
} from "../../../../components/OldDesign/Icons";
import SectionTitle from "../../../../components/NewDesign/SectionTitle";
import { getJobPosition, postJobPosition } from "../../../../api/endpoints";
import Loading from "../../../../components/OldDesign/Loading";
import _ from "lodash";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { REACT_BASE_URL } from "../../../../api/constants";
import axios from "axios";
import debounce from "../../../../utils/debouncer";
import { TagWithIcon } from "../../../../components/NewDesign/Tags";
import { JOBPOS_CORECV_FIELD_KEYS } from "../../../../components/NewDesign/constants";
import EditableOrientationCard from "../../../../components/NewDesign/EditableOrientationCard";
import { useStore } from "../../../../store/useStore";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Tooltip from "../../../../components/NewDesign/Tooltips/Tooltip";

const JobPositionResult = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [jobPosition, setJobPosition] = useState({ data: {}, isLoading: false, isLoaded: false });
  const [corecvEditMode, setCorecvEditMode] = useState(false);

  const openReportModal = () => {
    if (jobPosition.data.seat_metacv_count > 0) {
      navigate(`/analysis/${id}`, {
        state: { previousLocation: location },
      });
    } else {
      toast.error("Uyum raporunu görüntüleyebilmek için üye eklemelisiniz!");
    }
  };

  const handleOpenJobAnalysisModal = (title) => {
    navigate(`/jobposition/${id}/corecv`, {
      state: { previousLocation: location, jumpStep: title },
    });
  };

  useEffect(() => {
    getJobPosition(
      null,
      `/${id}`,
      (res) => {
        setJobPosition((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
      },
      (err) => {
        setJobPosition((prev) => {
          return { ...prev, error: "N/A" };
        });
      },
      (bool) => {
        setJobPosition((prev) => {
          return { ...prev, isLoading: bool };
        });
      },
    );
    setCorecvEditMode(false);
  }, [id, location.key]);

  if (jobPosition.isLoading) {
    return <Loading />;
  }
  if (!jobPosition.isLoaded) {
    return null;
  }

  return (
    <div className="max-lg:bg-secondary_recruiter-100">
      <div className="container2 pt-[64px] max-lg:mb-[56px] max-lg:pt-[54px]">
        <div className="flex flex-col gap-4 rounded-xl bg-secondary_recruiter-100 p-6 max-lg:p-0">
          {/* header */}
          <div className="flex items-center justify-between max-lg:flex-col max-lg:items-start max-lg:gap-3">
            <div className="flex items-center gap-4">
              <button type="button" onClick={() => navigate("/cna/ideals")}>
                <ChevronRightOutlined className={"h-6 w-6 rotate-180"} />
              </button>
              <div className="flex flex-col p-0.5">
                <h2 className="text-sm leading-tight text-primary-900">İş Pozisyonu Uygunluk Analizi</h2>
                <h4 className="text-xl font-semibold leading-tight text-primary-900">{jobPosition.data.name}</h4>
              </div>
            </div>
            <div>
              <Button
                onClick={() => openReportModal()}
                className="border-none bg-[linear-gradient(247deg,#82B7EA_2.82%,#7986E8_35.94%,#36099F_98.96%)] max-lg:p-2"
                disabled={jobPosition.data.seat_metacv_count < 1}
              >
                Uyum Raporunu Görüntüle
              </Button>
            </div>
          </div>
          {/* corecv&members */}
          <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-1">
            {/* corecv */}
            <div className="h-full lg:col-span-2">
              <div className="flex items-center justify-between">
                <p className="text-[13px] font-medium leading-tight text-secondary_applicant-600">
                  Olmazsa olmaz uyumluluğu
                </p>
                <div>
                  {corecvEditMode ? (
                    <Button
                      onClick={() => setCorecvEditMode(false)}
                      className="border-none bg-transparent p-3 text-card_candidate-400"
                      icon={<CloseOutlined className="h-5 w-5" />}
                    >
                      Iptal
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setCorecvEditMode(true)}
                      className="border-none bg-transparent p-3 text-neutral-700"
                      icon={<EditOutlined className="h-5 w-5" />}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap gap-4 max-lg:gap-3">
                {Object.keys(JOBPOS_CORECV_FIELD_KEYS).map((key, index) => {
                  return (
                    <ListBox
                      handleOpenJobAnalysisModal={handleOpenJobAnalysisModal}
                      editMode={corecvEditMode}
                      key={index}
                      title={key}
                    >
                      {jobPosition.data.core_cv[key].map((item, index) => {
                        return (
                          <ListItems
                            key={index}
                            item={
                              item.name ||
                              item.job_name ||
                              item.language_name ||
                              item.country_name ||
                              item.skill_name ||
                              item.institute_name ||
                              item.low + "-" + item.high
                            }
                            mustHave={item.importance === 0}
                          />
                        );
                      })}
                    </ListBox>
                  );
                })}
              </div>
            </div>
            {/* members */}
            <div className="lg:col-span-1">
              <MembersBox setJobPosition={setJobPosition} jobPosition={jobPosition} />
            </div>
          </div>
          {/* aid orders */}
          <AidSortBox jobPosition={jobPosition} setJobPosition={setJobPosition} />
          {/* professional orientations */}
          <ProfessionalOrientationBox setJobPosition={setJobPosition} jobPosition={jobPosition} />
        </div>
      </div>
    </div>
  );
};

const ListItems = ({ item, mustHave }) => {
  return (
    <li className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
      <p
        style={{ wordBreak: "break-word" }}
        className={`${
          mustHave ? "bg-secondary_applicant-600 px-2 py-1 text-neutral-100" : "text-primary-900"
        } rounded-full text-[14px] font-medium leading-tight`}
      >
        &#x2022; {item}
      </p>
    </li>
  );
};

const ListBox = ({ title, children, editMode = false, handleOpenJobAnalysisModal }) => {
  return (
    <div className="grow rounded-lg bg-neutral-100 p-4">
      <div className="flex items-center justify-between pb-3 max-lg:pb-2 lg:w-full">
        <h3 className="text-[17px] font-semibold leading-tight text-primary-900">{JOBPOS_CORECV_FIELD_KEYS[title]}</h3>

        <Button
          onClick={() => handleOpenJobAnalysisModal(title)}
          disabled={!editMode}
          className={`${editMode ? "visible" : "invisible"} border-none bg-transparent p-0 transition-all max-lg:p-0`}
          icon={<EditOutlined className={"h-4 w-4 text-neutral-700"} />}
        />
      </div>
      <ul className="flex flex-wrap gap-2">{children}</ul>
    </div>
  );
};

const AidSortBox = ({ jobPosition, setJobPosition }) => {
  const strapiContent = useStore((state) => state.strapiContent);
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const aidOrder = jobPosition.data.aid.aid.map((aid) => {
    const index = _.findIndex(strapiContent.data, (item) => item.attributes.humanas_id === aid.id);
    return strapiContent.data[index];
  });

  const [aidItems, setAidItems] = useState(aidOrder);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setAidItems((items) => {
        const oldIndex = items.map((item) => item.id).indexOf(active.id);
        const newIndex = items.map((item) => item.id).indexOf(over.id);
        // const newArray = arrayMove(items, oldIndex, newIndex);
        // console.log("yeni array", active, over);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
  const handleSubmitAidOrder = () => {
    const payload = aidItems.map((aid) => {
      return { id: aid.attributes.humanas_id };
    });

    postJobPosition(
      null,
      { aid: { aid: [...payload] } },
      `/${jobPosition.data.id}`,
      (res) => {
        toast.success("Güncellendi");
        setJobPosition((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
      },
      (err) => {
        toast.error("Güncellenemedi");
        setJobPosition((prev) => {
          return { ...prev, error: "N/A" };
        });
      },
      (bool) => {
        setSubmitting(bool);
      },
    );
    setEditMode(false);
  };

  const AidListItem = ({ aid }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: aid.id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`flex items-center gap-2 rounded-lg  bg-neutral-200 px-3 py-2 max-lg:flex-col`}
      >
        <div className="flex items-start gap-2 self-start lg:items-center">
          <div>
            <Tooltip content={aid.attributes.description}>
              <InfoOutlined className={"h-3 w-3 text-neutral-400"} />
            </Tooltip>
          </div>
          <div
            style={{
              background: `linear-gradient(180deg, ${aid.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
            }}
            className={`h-6 w-6 rounded-full shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-6 max-lg:w-6`}
          >
            <img
              src={process.env.REACT_APP_STRAPI_BASE_URL + aid.attributes.logo.data.attributes.url}
              alt={aid.attributes.name}
              className=""
            />
          </div>
          <div className="flex items-center gap-1">
            <p className="font-semibold leading-tight text-neutral-800">{aid.attributes.name}</p>
            <p className="text-sm leading-tight text-neutral-800">{aid.attributes.title}</p>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-1">
          {aid.attributes.keyword.map((keyword, index) => (
            <TagWithIcon key={index} text={keyword.keyword} className={"bg-neutral-100 text-secondary_recruiter-700"} />
          ))}
        </div>
        <div className="ml-auto">
          <ColumnsOutlined className={`${!editMode ? "scale-0" : "scale-100"} h-4 w-4 transition-all`} />
        </div>
      </div>
    );
  };
  return (
    <div className="touch-none">
      <SectionTitle title="Humanas AID" className="w-1/2 items-start pb-3 max-lg:pb-2" />
      <p className="text-sm font-medium leading-tight text-primary-900">
        Humanas AID, iş süreçlerinde en çok ihtiyaç duyulan çalışan özelliklerinin derlenerek bir araya geldiği
        kimlikleri ifade etmektedir.
      </p>
      <div className="flex items-center justify-between max-lg:flex-col max-lg:items-start">
        <p className="text-sm font-medium leading-tight text-primary-900">
          Önce kimlikleri inceleyin, ardından ihtiyacınıza göre sıralamanızı yapın.
        </p>
        <div className="self-end">
          {editMode ? (
            <>
              {/* <Button
                // onClick={() => handleResetToTournamentResult(jobPosition.data.id)}
                className="border-none bg-transparent p-3 text-neutral-700"
                icon={<WaitingOutlined className="h-5 w-5" />}
                loading={submitting}
              >
                Sıfırla
              </Button> */}
              <Button
                onClick={() => handleSubmitAidOrder()}
                className="border-none bg-transparent p-3 text-success-400"
                icon={<TickOutlined className="h-5 w-5" />}
                loading={submitting}
              >
                Kaydet
              </Button>
              <Button
                onClick={() => {
                  setAidItems(aidOrder);
                  setEditMode(false);
                }}
                className="border-none bg-transparent p-3 text-card_candidate-400"
                icon={<CloseOutlined className="h-5 w-5" />}
                loading={submitting}
              >
                Iptal
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setEditMode(true)}
              className="border-none bg-transparent p-3 text-neutral-700"
              icon={<EditOutlined className="h-5 w-5" />}
              loading={submitting}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1.5 rounded-xl bg-neutral-100 p-4">
        {editMode ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={aidItems} strategy={verticalListSortingStrategy}>
              {aidItems.map((aid, index) => (
                <AidListItem
                  key={index}
                  aid={aid}
                  index={index}
                  // onClick={() => handleAidSelect(aid.attributes.codeName)}
                  // active={selectedAid === aid.attributes.codeName}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <>
            {aidItems.map((aid, index) => (
              <AidListItem
                key={index}
                aid={aid}
                index={index}
                // onClick={() => handleAidSelect(aid.attributes.codeName)}
                // active={selectedAid === aid.attributes.codeName}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const ProfessionalOrientationBox = ({ setJobPosition, jobPosition }) => {
  const [orientationEditMode, setOrientationEditMode] = useState(false);
  const [editedOrientations, setEditedOrientations] = useState(
    JSON.parse(JSON.stringify(jobPosition.data.professional_orientations.dimensions)),
  );
  const [submitting, setSubmitting] = useState(false);
  const resetToTournamentResult = useStore((state) => state.resetToTournamentResult);
  const handleOrientationEdit = (val, leftId) => {
    const newOrientations = [...editedOrientations];
    const changedIndex = _.findIndex(newOrientations, (item) => item.id_left === leftId);
    if (val > 50) {
      newOrientations[changedIndex].value = val - 50;
      newOrientations[changedIndex].direction = 1;
    } else {
      newOrientations[changedIndex].value = 50 - val;
      newOrientations[changedIndex].direction = 0;
    }
    setEditedOrientations(newOrientations);
  };

  const handleSumbitNewOrientation = () => {
    postJobPosition(
      null,
      { professional_orientations: { dimensions: editedOrientations } },
      `/${jobPosition.data.id}`,
      (res) => {
        toast.success("Mesleki Yönelim Güncellendi");
        setJobPosition((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
        setOrientationEditMode(false);
      },
      (err) => {
        console.log(err);
        setSubmitting(false);
      },
      (bool) => setSubmitting(bool),
    );
  };

  const handleResetToTournamentResult = (jobPosId) => {
    getJobPosition(
      null,
      `/${jobPosId}/identityreset`,
      (res) => {
        toast.success("Mesleki Yönelim Güncellendi");
        setJobPosition((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
        setOrientationEditMode(false);
      },
      (err) => {
        console.log(err);
        setSubmitting(false);
      },
      (bool) => setSubmitting(bool),
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <SectionTitle title="Mesleki Yönelim" className="w-1/2 pb-3 max-lg:pb-2 lg:items-start" />
        <div>
          {orientationEditMode ? (
            <>
              <Button
                onClick={() => handleResetToTournamentResult(jobPosition.data.id)}
                className="border-none bg-transparent p-3 text-neutral-700"
                icon={<WaitingOutlined className="h-5 w-5" />}
                loading={submitting}
              >
                Sıfırla
              </Button>
              <Button
                onClick={() => handleSumbitNewOrientation()}
                className="border-none bg-transparent p-3 text-success-400"
                icon={<TickOutlined className="h-5 w-5" />}
                loading={submitting}
              >
                Kaydet
              </Button>
              <Button
                onClick={() => {
                  setEditedOrientations(
                    JSON.parse(JSON.stringify(jobPosition.data.professional_orientations.dimensions)), // Deep copy on cancel
                  );
                  setOrientationEditMode(false);
                }}
                className="border-none bg-transparent p-3 text-card_candidate-400"
                icon={<CloseOutlined className="h-5 w-5" />}
                loading={submitting}
              >
                Iptal
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setOrientationEditMode(true)}
              className="border-none bg-transparent p-3 text-neutral-700"
              icon={<EditOutlined className="h-5 w-5" />}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {jobPosition.data.professional_orientations.dimensions.map((orientation) => {
          return (
            <EditableOrientationCard
              onChange={(val) => {
                handleOrientationEdit(val, orientation.id_left);
              }}
              key={`${orientation.id_combined}-${orientationEditMode}`}
              editMode={orientationEditMode}
              orientation={orientation}
              orientationContent={
                jobPosition.data.professional_orientations.dimensions_content[orientation.id_combined]
              }
            />
          );
        })}
      </div>
    </div>
  );
};

const MembersBox = ({ setJobPosition, jobPosition }) => {
  const [submitting, setSubmitting] = useState(false);
  const [copied, setCopied] = useState(false);
  const [membersEditMode, setMembersEditMode] = useState(false);
  const [inviteLinkMode, setInviteLinkMode] = useState(false);
  const [editableMembers, setEditableMembers] = useState(jobPosition.data.members);

  const loadNetworkList = debounce(async (value, callback) => {
    const apiKey = localStorage.getItem("token");
    const payload = {
      type: "connections",
      page: 1,
      rows: 200,
      keyword: value,
      search: {
        scope: "self",
        filters: {
          usergroups: [],
          usersource: [],
          work_info: [],
          personality: [],
          badge: [],
        },
        sort: {
          sortby: "metacv_creation_date",
          ascending: true,
        },
      },
    };
    axios
      .post(`${REACT_BASE_URL}/company/mynetwork`, payload, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      })
      .then((res) => callback(res.data.data.rows));
  }, 300);

  const handleEditMembers = (selectedMember) => {
    const newMembers = _.unionBy(editableMembers, [selectedMember], "id");
    setEditableMembers(newMembers);
  };
  const handleRemoveMember = (member) => {
    const newMembers = editableMembers.filter((value) => value.id !== member.id);
    setEditableMembers(newMembers);
  };
  const handleSubmitMembers = () => {
    const membersIDs = editableMembers.map((member) => {
      return { id: member.id };
    });
    // console.log("membersIds", membersIDs);
    postJobPosition(
      null,
      { members: membersIDs },
      `/${jobPosition.data.id}`,
      (res) => {
        toast.success("Uyeler Güncellendi");
        setJobPosition((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
        setMembersEditMode(false);
      },
      (err) => console.log(err),
      (bool) => setSubmitting(bool),
    );
  };
  const handleCancelEdits = () => {
    setEditableMembers(jobPosition.data.members);
    setMembersEditMode(false);
  };
  const copyLinkToClipboard = async (linkToBeCopied) => {
    try {
      clearTimeout();
      navigator.clipboard.writeText(linkToBeCopied).then(() => setCopied(true));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };
  return (
    <div className="h-full flex-1 overflow-hidden rounded-xl bg-neutral-100 max-lg:min-h-[320px]">
      <div className="flex items-center justify-between p-6 pb-2 max-lg:p-3">
        <div className="flex flex-col gap-0.5 max-lg:flex-row">
          <h5 className="text-xl font-bold leading-tight text-primary-900 max-lg:text-base">Üyeler</h5>
          <div className="flex items-center gap-1">
            <PeopleFilled className="h-3 w-3 text-primary-900" />
            <p className="text-sm leading-tight text-neutral-800 max-lg:text-[13px]">
              {jobPosition.data.members.length}/{jobPosition.data.seat_count}
            </p>
          </div>
        </div>
        <div className="flex gap-1">
          {membersEditMode ? (
            <>
              <Button
                loading={submitting}
                onClick={() => handleSubmitMembers()}
                className="gap-1 border-none bg-transparent p-2 text-success-400 max-lg:p-2 max-lg:text-sm"
                icon={<TickOutlined className="h-5 w-5" />}
              >
                Kaydet
              </Button>
              <Button
                onClick={() => handleCancelEdits()}
                className="gap-1 border-none bg-transparent p-2 text-card_candidate-400 max-lg:p-2 max-lg:text-sm"
                icon={<CloseOutlined className="h-5 w-5" />}
              >
                Iptal
              </Button>
            </>
          ) : inviteLinkMode ? (
            <>
              <Button
                loading={submitting}
                onClick={() => setInviteLinkMode(false)}
                className="gap-1 border-none bg-transparent p-2 text-success-400 max-lg:p-2 max-lg:text-sm"
                icon={<TickOutlined className="h-5 w-5" />}
              >
                Tamam
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => setInviteLinkMode(true)}
                className="gap-1 border-none bg-transparent p-2 text-neutral-700 max-lg:p-2 max-lg:text-sm"
                icon={<ShareOutlined className="h-5 w-5" />}
              >
                Davet et
              </Button>
              <Button
                onClick={() => setMembersEditMode(true)}
                className="gap-1 border-none bg-transparent p-2 text-neutral-700 max-lg:p-2 max-lg:text-sm"
                icon={<PlusOutlined className="h-5 w-5" />}
              >
                Ekle/Çıkar
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        className={`${
          membersEditMode ? "mb-2 h-[53px] scale-100" : inviteLinkMode ? "mb-2 h-[70px] scale-100" : " mb-0 h-0 scale-0"
        } relative z-10 px-6 transition-all`}
      >
        {membersEditMode ? (
          <li
            className={`flex h-full w-full items-center justify-between rounded-xl border-2 border-dashed border-primary-400 bg-primary-75 transition-all hover:bg-primary-100`}
          >
            <AsyncSelect
              defaultOptions
              loadOptions={loadNetworkList}
              placeholder="+ Yeni Kişi Ekle"
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.id}
              cacheOptions={true}
              onChange={handleEditMembers}
              styles={{
                multiValue: (styles, state) => ({
                  ...styles,
                  backgroundColor: "#CFE1EE",
                  borderRadius: "12px",
                  maxWidth: "200px",
                  color: "#106BAC",
                  ":hover": "#FFFFFF",
                }),
                multiValueLabel: (styles, state) => ({
                  ...styles,
                  color: "#106BAC",
                }),
                placeholder: (styles, state) => ({
                  ...styles,
                  color: "#9A9ABA",
                }),
                menuList: (styles, state) => ({
                  ...styles,
                  maxHeight: "200px",
                }),
                option: (styles, state) => ({
                  ...styles,
                  backgroundColor: editableMembers.find((item) => item.id === state.data.id) ? "#106BAC50" : "white",
                  color: editableMembers.find((item) => item.id === state.data.id) ? "white" : "black",
                }),
                control: (styles, state) => ({
                  ...styles,
                  borderStyle: "dashed",
                  borderRadius: "12px",
                  borderColor: state.isFocused ? "#DFDFEE" : "#106BAC00",
                  borderWidth: "0px",
                  boxShadow: "none",
                  backgroundColor: state.isFocused ? "#FFFFFF" : "#FFFFFF00",
                  width: "100%",
                }),
                valueContainer: (styles, state) => ({
                  ...styles,
                  minHeight: "50px",
                  maxHeight: "130px",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  overflowY: "auto",
                  textAlign: "center",
                }),
                container: (styles, state) => ({
                  ...styles,
                  width: "100%",
                }),
                singleValue: (styles, state) => ({
                  ...styles,
                  color: state.isFocused ? "black" : "#9A9ABA",
                }),
              }}
            />
          </li>
        ) : (
          <div className="flex w-full flex-col gap-0.5">
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-semibold">Aday</h3>
              <button
                onClick={() => copyLinkToClipboard(jobPosition.data.invite_link_candidate)}
                disabled={copied}
                className="relative ml-auto max-w-[300px] cursor-copy rounded-xl border p-1.5 pr-6 text-start max-lg:max-w-[240px]"
              >
                {<p className="line-clamp-1 text-sm text-neutral-700">{jobPosition.data.invite_link_candidate}</p>}
                <div className="absolute right-1 top-1/2 -translate-y-1/2 p-1">
                  {copied ? (
                    <TickOutlined className="h-5 w-5 text-success-400" />
                  ) : (
                    <CopyOutlined className="h-5 w-5 text-secondary_recruiter-600" />
                  )}
                </div>
              </button>
            </div>
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-semibold">Çalışan</h3>
              <button
                onClick={() => copyLinkToClipboard(jobPosition.data.invite_link_employee)}
                disabled={copied}
                className="relative ml-auto max-w-[300px] cursor-copy rounded-xl border p-1.5 pr-6 text-start max-lg:max-w-[240px]"
              >
                {<p className="line-clamp-1 text-sm text-neutral-700">{jobPosition.data.invite_link_employee}</p>}
                <div className="absolute right-1 top-1/2 -translate-y-1/2 p-1">
                  {copied ? (
                    <TickOutlined className="h-5 w-5 text-success-400" />
                  ) : (
                    <CopyOutlined className="h-5 w-5 text-secondary_recruiter-600" />
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
      <ul
        className={`${
          membersEditMode ? "lg:min-h-[calc(100%-160px)]" : "lg:min-h-[calc(100%-93px)]"
        } flex flex-col gap-3 overflow-y-auto px-4 max-lg:max-h-[332px] max-lg:pb-4 lg:h-0`}
      >
        {editableMembers.map((member, index) => {
          return (
            <MemberRow
              handleRemoveMember={handleRemoveMember}
              editMode={membersEditMode}
              key={index}
              member={member}
              newAdded={!jobPosition.data.members.find((item) => item.id === member.id)}
            />
          );
        })}
      </ul>
    </div>
  );
};

const MemberRow = ({ handleRemoveMember, member, editMode = true, invitePending = false, newAdded = false }) => {
  return (
    <li className="flex w-full items-center justify-between rounded-xl border border-neutral-300 p-3 transition-all hover:bg-neutral-200">
      <div className="flex gap-2">
        <p className="font-semibold leading-tight text-primary-800">{member.name}</p>
        {newAdded && <TagWithIcon className={"bg-success-100 py-0.5 text-xs text-success-400"} text={"Yeni"} />}
      </div>
      <Button
        onClick={() => handleRemoveMember(member)}
        className={`${
          editMode ? "scale-100 opacity-100" : "scale-0 opacity-0"
        } border-none bg-card_candidate-100 p-1.5 text-card_candidate-400 transition-all`}
        icon={<RubbishOutlined className={"h-5 w-5"} />}
      />
    </li>
  );
};

export default JobPositionResult;
