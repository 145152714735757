import React, { useEffect, useState } from "react";
import { ConnectionsWorld } from "../../../components/NewDesign/Illustrations";
import UserCard from "../../../components/NewDesign/UserCard";
import SearchBar from "../../../components/NewDesign/SearchBar/SearchBar";
import SortBar from "../../../components/NewDesign/SortBar";
import { useStore } from "../../../store/useStore";
import GroupFilters from "../../../components/NewDesign/GroupFilters";
import Loading from "../../../components/OldDesign/Loading";
import { useLocation } from "react-router-dom";
import Pagination from "../../../components/OldDesign/Pagination";
import Button from "../../../components/NewDesign/Button/Button";
import { FieldCheckbox } from "../../../components/NewDesign/Forms/Field";
import { Checkbox } from "../../../components/NewDesign/Forms/Input";
import { ArchiveFilled } from "../../../components/OldDesign/Icons";
import {
  CONNECTIONS_BADGE,
  CONNECTIONS_GROUPS,
  CONNECTIONS_PERSONALITY,
  CONNECTIONS_SORT_OPTIONS,
  CONNECTIONS_SOURCES,
  CONNECTIONS_WORKINFO,
} from "../../../components/NewDesign/constants";

const Connections = () => {
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const getNetworkConnections = useStore((state) => state.getNetworkConnections);
  const appLanguage = useStore((state) => state.appLanguage);
  const { state } = useLocation();
  const listType = "connections";

  // const getNetworkList = useStore((state) => state.getNetworkList);

  const clearNetworkList = useStore((state) => state.clearNetworkList);
  const toggleSelectAll = useStore((state) => state.toggleSelectAll);
  const clearSelectedUsers = useStore((state) => state.clearSelectedUsers);
  const selectedUsers = useStore((state) => state.selectedUsers);
  const networkList = useStore((state) => state.networkList);
  const [sortOption, setSortOption] = useState({
    sortby: "metacv_creation_date",
    ascending: true,
  });

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedUserGroups, setSelectedUserGroups] = useState(
    state?.stateSelectedGroupFilter
      ? [state.stateSelectedGroupFilter] // Filters redirect from stat card
      : [], // Filters default
  );
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedWorkInfo, setSelectedWorkInfo] = useState([]);
  const [selectedPersonality, setSelectedPersonality] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(
    state?.stateSelectedAidFilter
      ? [state.stateSelectedAidFilter] // Filters redirect from stat card
      : [], // Filters default
  );

  const [actionCount, setActionCount] = useState(0);

  // PAGINATION
  const [pageSize, setPageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSelectAll = (e) => {
    toggleSelectAll(e.target.checked, networkList.memberList);
  };

  let checkSubset = (parentArray, subsetArray) => {
    if (subsetArray.length > 0) {
      return subsetArray.every((el) => {
        return parentArray.some((p) => p.id === el.id);
      });
    } else return false;
  };

  const toggleArchive = () => {
    setSelectedSources([]);
    setSearchKeyword("");
    setSelectedUserGroups([]);
    setSelectedWorkInfo([]);
    setCurrentPage(1);

    if (selectedUserGroups.includes("archives")) {
      setSelectedUserGroups((prev) => [...prev].filter((item) => item !== "archives"));
    } else {
      setSelectedUserGroups(["archives"]);
    }
  };

  useEffect(() => {
    const options = {
      // type: listType,
      page: currentPage,
      rows: pageSize,
      keyword: searchKeyword,
      filter: {
        user_groups: selectedUserGroups,
        user_source: selectedSources,
        work_info: selectedWorkInfo,
        personality: selectedPersonality,
        badge: selectedBadge,
      },
      // sort: sortOption,
    };
    // getNetworkList(options);
    getNetworkConnections(options);
    return () => {
      clearNetworkList();
      // clearSelectedUsers();
    };
  }, [
    getNetworkConnections,
    clearNetworkList,
    clearSelectedUsers,
    actionCount,
    pageSize,
    currentPage,
    searchKeyword,
    selectedUserGroups,
    selectedSources,
    selectedWorkInfo,
    selectedPersonality,
    selectedBadge,
    appLanguage,
    sortOption,
  ]);
  return (
    <>
      {/* <p className="pb-2 text-[14px]">{content["connections aciklama"]}</p> */}
      <div className="flex flex-wrap items-center gap-2 py-4 max-lg:py-2">
        <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        {/* <FilterBar /> */}
        <SortBar sortOptions={CONNECTIONS_SORT_OPTIONS} sortOption={sortOption} setSortOption={setSortOption} />

        <FieldCheckbox
          className={`${
            selectedUsers.length === 0 ? "max-lg:invisible max-lg:bottom-[0]" : "max-lg:visible max-lg:bottom-[80px]"
          } ml-auto h-[33px] whitespace-nowrap rounded-3xl border-neutral-400 bg-neutral-100 p-2 text-[14px] font-medium text-neutral-600 transition-all  max-lg:fixed max-lg:right-1/2 max-lg:translate-x-1/2`}
          label={"Tümünü Seç"}
        >
          <Checkbox checked={checkSubset(selectedUsers, networkList.memberList)} onChange={handleSelectAll} />
        </FieldCheckbox>
        <Button
          className={`${
            selectedUserGroups.includes("archives")
              ? "border-primary-100 bg-primary-100 text-primary-600"
              : "border-neutral-400 bg-neutral-100 text-neutral-400"
          } rounded-full  p-2 max-lg:p-2`}
          onClick={toggleArchive}
          icon={<ArchiveFilled className={"h-[15px] w-[15px]"} />}
          title={"Arşiv"}
        ></Button>
      </div>

      {/* Filters */}
      <GroupFilters
        setCurrentPage={setCurrentPage}
        fetching={networkList.isLoading}
        allUserCounts={networkList.allUserCounts}
        sourceFilters={CONNECTIONS_SOURCES}
        selectedSources={selectedSources}
        setSelectedSources={setSelectedSources}
        workFilters={CONNECTIONS_WORKINFO}
        selectedWorkInfo={selectedWorkInfo}
        setSelectedWorkInfo={setSelectedWorkInfo}
        userGroupFilters={CONNECTIONS_GROUPS}
        selectedUserGroups={selectedUserGroups}
        setSelectedUserGroups={setSelectedUserGroups}
        personalityFilters={CONNECTIONS_PERSONALITY}
        selectedPersonality={selectedPersonality}
        setSelectedPersonality={setSelectedPersonality}
        badgeFilters={CONNECTIONS_BADGE}
        selectedBadge={selectedBadge}
        setSelectedBadge={setSelectedBadge}
      />

      {networkList.isLoading ? (
        <Loading />
      ) : !networkList.memberList.length ? (
        <div className="py-12">
          <ConnectionsWorld className="mx-auto w-1/2 pb-9" />
          <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
            {content["bos tablo aciklama"]}
          </p>
        </div>
      ) : (
        <>
          {/* List */}
          <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-1">
            {networkList.memberList.map((user, index) => {
              return (
                <UserCard
                  setActionCount={setActionCount}
                  checked={selectedUsers.some((_user) => _user.id === user.id)}
                  key={user.id}
                  user={user}
                  listType={listType}
                />
              );
            })}
          </div>
          <div className="py-2">
            <Pagination
              dataLength={networkList.paginationInfo.total_row_count}
              setPageSize={setPageSize}
              currentPage={currentPage}
              totalCount={networkList.paginationInfo.total_row_count}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Connections;
