import { ChevronDownOutlined } from "components/OldDesign/Icons";
import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "utils/useIsMobile";

const Submenu = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isActive = location.pathname.includes(item.path);
  const [subMenu, setSubMenu] = useState(isActive);
  const openSubMenu = () => {
    if (item.sub.length) {
      setSubMenu(!subMenu);
    } else {
      navigate(item.path, { state: { previousLocation: location.state.previousLocation } });
    }
  };
  return (
    <div>
      <div
        onClick={openSubMenu}
        className={`${
          isActive
            ? "bg-secondary_recruiter-100 font-bold text-secondary_recruiter-700"
            : "bg-neutral-100 font-normal text-neutral-600"
        } my-1.5 flex cursor-pointer items-center justify-between rounded-lg p-2`}
      >
        <div>{item.title}</div>
        <div>
          {item.sub.length ? (
            <ChevronDownOutlined className={`${subMenu ? "rotate-180" : "rotate-0"} h-3 w-3 transition-all`} />
          ) : null}
        </div>
      </div>
      {subMenu &&
        item.sub.map((subItem) => {
          return (
            <NavLink
              key={subItem.title}
              state={{ previousLocation: location.state.previousLocation }}
              to={subItem.path}
              className={`flex p-2 pl-6`}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "600" : "400",
                  color: isActive ? "#4d5aa6" : "#9A9ABA",
                };
              }}
            >
              <div>- {subItem.title}</div>
            </NavLink>
          );
        })}
    </div>
  );
};

export default Submenu;
