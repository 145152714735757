import { Guideline } from "data/Guideline";
import Submenu from "./Submenu";

const Sidebar = ({ title }) => {
  return (
    <div className="overflow-y-auto rounded-lg border border-neutral-300 p-5 max-lg:w-full max-lg:p-3 lg:w-[265px]">
      <h3 className="text-3xl font-bold text-primary-900 max-lg:text-xl">{title}</h3>
      <div className="flex flex-col divide-y-2">
        {Guideline.guides.map((guide) => {
          return <Submenu key={guide.title} item={guide} />;
        })}
      </div>
    </div>
  );
};

export default Sidebar;
