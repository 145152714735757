import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { LeftCircularOutlined } from "../../../OldDesign/Icons";
import { REACT_BASE_URL } from "../../../../api/constants";
import { Input, TextArea } from "../../Forms/Input";

const AboutCard = (props) => {
  const appLanguage = useStore((state) => state.appLanguage);
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      user_bio: coreCV.user_bio || "",
    },
    mode: "onSubmit",
  });

  const sendFormData = async (formData) => {
    // console.log(formData);
    await updateApplicationForm(formData, props.handleForwardForm);
  };
  const aboutContent = {
    tr: {
      aboutTitle: "Hakkında",
      aboutDescription: "Lütfen kendinizi birkaç cümle ile açıklayınız.",
    },
    en: {
      aboutTitle: "About",
      aboutDescription: "Please describe yourself in a few sentences.",
    },
  };
  return (
    <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
      <fieldset className="flex flex-col gap-4">
        <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
          {aboutContent[appLanguage].aboutTitle}
        </h2>
        <Field label={aboutContent[appLanguage].aboutTitle} error={errors?.user_bio}>
          <TextArea
            rows={9}
            placeholder={aboutContent[appLanguage].aboutDescription}
            maxLength={510}
            {...register("user_bio", { required: "Required", maxLength: 510 })}
          />
        </Field>
      </fieldset>
      <div className="flex justify-center gap-20">
        <button disabled={isSubmitting} type="button" id="backwardArrow" onClick={() => props.handleBackwardForm()}>
          <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
        </button>
        <button disabled={isSubmitting} type="submit" id="forwardArrow">
          <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
        </button>
      </div>
    </Form>
  );
};

export default AboutCard;
