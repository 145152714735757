import myconnections from "../../../../assets/guides/guide_network.png";
import filters from "../../../../assets/guides/guide_filters.png";

const MyConnections = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-xl bg-neutral-200 p-4">
        <div className="flex flex-col gap-4">
          <p className="text-sm leading-tight text-neutral-800">
            Bağlantılarım sekmesinden, hazır olan Meta CV’lere ulaşabilirsiniz. Adaylarınız ve çalışanlarınızla bağlantı
            kurabilir, profil bilgilerini inceleyerek onları daha yakından tanıyabilirsiniz. Filtreler ve sıralama
            seçenekleri sayesinde dilediğiniz kişilere daha hızlı erişebilir, profil kartlarından Meta CV’lerini
            inceleyebilirsiniz. Dilediğiniz kişileri favorilerinize ekleyerek daha sonra tekrar ulaşabilirsiniz.
          </p>
          <img src={myconnections} alt="myconnections" className="h-full w-full object-cover" />
        </div>
      </div>
      <div className="rounded-xl bg-neutral-200 p-4">
        <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Akıllı Filtreler</h3>
        <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
          <p className="text-sm leading-tight text-neutral-800 lg:w-1/3">
            Akıllı Filtreler, kullanıcıların bağlantı listelerinde yer alan adayları ve çalışanları daha hızlı ve kolay
            bir şekilde bulmalarını sağlar. Filtreleme seçenekleri, adayların veya çalışanların çalışma yerinden kişilik
            tipine kadar farklı kriterlere göre arama yapmanıza olanak tanır.
          </p>
          <img src={filters} alt="favorites" className="h-full object-cover max-lg:w-[300px] lg:w-2/3" />
        </div>
      </div>
    </div>
  );
};

export default MyConnections;
